
                        @import "src/assets/less/variables.less";
                    
.assets {
    .assets-nav-list {
        font-family: "Race Sport", sans-serif;
        color: @mf-text-color;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        font-size: 22px;
        line-height: 32px;
        cursor: pointer;
    }

    .assets-ink-bar, .assets-nav-operations {
        display: none;
    }

    .assets-tab {
        transition: all .3s;
    }

    .assets-tab:hover, .assets-tab-active {
        color: @mf-primary-color;
    }

    .assets-nav {
        margin-bottom: 20px;
    }

    @media(min-width: @mf-screen-sm-min) {
        .assets-nav-list {
            font-size: 36px;
            line-height: 42px;
        }

        .assets-nav {
            margin-bottom: 60px;
        }
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;