
                        @import "src/assets/less/variables.less";
                    
.algem-wrapper {
    margin-top: 40px;
    position: relative;
    padding: 120px 0;
    background: url(../../../assets/images/algem/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .section-shape {
        z-index: 0;
    }
    .section-shape-beast-1 {
        top: 12%;
        left: 8%;

        img {
            scale: 1;
        }
    }

    .section-shape-beauty-2 {
        top: 38%;
        right: 7%;

        img {
            scale: 1;
            transform: rotate(0);
        }
    }
    .section-shape-mfg-1 {
        top: 30%;
        left: 15%;
    }
    .section-shape-mfg-2 {
        top: 9%;
        right: 15%;
    }
    .section-shape-mfr-1 {
        top: 25%;
        left: 5%;

        img {
            scale: 1.3;
            transform: rotate(0);
        }
    }
    .section-shape-mfr-2 {
        top: 52%;
        right: 2%;
    }
    .algem-1 {
        top: 49%;
        left: 7%;
    }

    .algem-2 {
        top: 16%;
        right: 7%;
        left: unset;
    }
}
.footer-algem {
    text-align: center;
    padding-top: 100px;
    .footer__socials {
        a {
            background-color: #4ccbc9;
            color: #020722;
            svg path {
                fill: #020722;
            }
        }
    }
    .footer__contact {
        a {
            font-size: 20px;
            text-transform: uppercase;
        }
    }
    .footer__contact a,
    .footer__copyright {
        color: #ffffff;
    }
    .footer__contact {
        svg path {
            fill: #ffffff;
        }
    }
}
@media (max-width: 1200px) {
    .algem-wrapper {
        padding: 170px 0 220px;
    }
}
@media (max-width: 1140px) {
    .algem-wrapper {
        .section-shape-beast-1,
        .section-shape-beauty-1,
        .algem-1,
        .algem-2 {
            display: none;
        }
    }
}
@media (max-width: 996px) {
    .algem-wrapper {
        padding: 150px 10px 220px;
    }
}
// @media (max-width: 768px) {
//     .algem-wrapper {
//         padding: 100px 0 150px;
//     }
// }

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;