
                        @import "src/assets/less/variables.less";
                    
.event-winner {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    width: 100%;
    padding: 7px 0;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    background-color: #000;
    a {
        margin: 0 5px;
    }
    &.christmas {
        a {
            margin: 0;
        }
    }
    &.valentine {
        background-color: #a16bd8;
        a {
            margin: 0;
            span {
                font-weight: 700;
            }
        }
    }
    // .marquee {
    //     width: 100%;
    //     height: 25px;
    //     white-space: nowrap;
    //     overflow: hidden;
    //     box-sizing: border-box;
    // }
    // .marquee .single {
    //     position: absolute;
    //     margin: 0;
    //     line-height: 25px;
    //     white-space: nowrap;
    //     animation: marquee 10s linear infinite;
    // }
    // .marquee .multiple {
    //     position: absolute;
    //     margin: 0;
    //     line-height: 25px;
    //     white-space: nowrap;
    //     animation: marquee 50s linear infinite;
    // }
    // @media (max-width: 768px) {
    //     .marquee .multiple {
    //         animation: marquee 70s linear infinite;
    //     }
    // }
    // @keyframes marquee {
    //     from {
    //         transform: translate(0, 0);
    //     }
    //     to {
    //         transform: translate(-100%, 0);
    //     }
    // }
}
@media (max-width: 768px) {
    .event-winner {
        font-size: 24px;
        // .scroll-text {
        //     width: 180%;
        //     text-align: left;
        //     display: block;
        //     transform: translateX(60%);
        //     -moz-animation: running-text 15s linear infinite;
        //     -webkit-animation: running-text 15s linear infinite;
        //     animation: running-text 15s linear infinite;
        // }
    }
}
/* for Firefox */
@-moz-keyframes running-text {
    to {
        -moz-transform: translateX(-100%);
    }
}

/* for Chrome */
@-webkit-keyframes running-text {
    to {
        -webkit-transform: translateX(-100%);
    }
}

@keyframes running-text {
    to {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}
.header {
    padding: 15px 24px;
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 20;
    background-color: @mf-bg-1;
    transition: background-color 0.3s;
    .inner {
        a {
            color: #fff;

            &::after {
                height: 2px;
                bottom: -2px;
            }

            &:hover {
                color: @mf-primary-color;
            }

            &.active {
                color: @mf-primary-color;
                border-bottom: 2px solid @mf-primary-color;
            }
        }
    }
    &.valentine {
        background-color: #fff1fa;
        .inner {
            a {
                color: #020722;
            }
        }
        .header__wallet-address {
            color: #020722;
            border: 1px solid #f6d9ec;
            background-color: #f6d9ec;
            &:hover {
                box-shadow: 0 0 0 0.2rem #f6d9ec;
            }
        }
        .ant-btn-text {
            svg path {
                stroke: #000;
            }
        }
    }
    &.summer-event {
        .header__wallet-address {
            color: #ffffff;
            border: 1px solid #0e354f;
            background-color: #0e354f;
            &:hover {
                box-shadow: 0 0 0 0.2rem #0e354f;
            }
        }
    }

    &.lunar-event {
        .header__wallet-address {
            color: #ffffff;
            border: 1px solid #0e354f;
            background-color: #0e354f;
            &:hover {
                box-shadow: 0 0 0 0.2rem #0e354f;
            }
        }
    }

    &.header-top {
        top: 0;
    }

    &.is-top {
        background-color: transparent;
        padding-top: 15px;
        padding-bottom: 15px;
        &.valentine {
            .inner {
                a {
                    color: #fff;
                }
            }
            .ant-btn-text {
                svg path {
                    stroke: #fff;
                }
            }
        }
        &.summer-event {
            .inner {
                a {
                    color: #0e354f;
                    font-size: 18px;
                    font-weight: 700;
                }
            }
            .ant-btn-text {
                svg path {
                    stroke: #0e354f;
                }
            }
        }
        &.hashkey-did,
        &.starfish {
            .inner {
                a {
                    color: #000000;
                    font-size: 18px;
                    font-weight: 700;
                }
            }
            .ant-btn-text {
                svg path {
                    stroke: #000000;
                }
            }
        }
        &.lunar-event {
            .inner {
                a {
                    color: #551f00;
                    font-family: Darker Grotesque;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                    text-transform: uppercase;
                }
            }
            .ant-btn-text {
                svg path {
                    stroke: #0e354f;
                }
            }
        }
    }

    .site-logo img {
        width: 175px;
    }

    .header__desktop-nav {
        display: flex;
        justify-content: center;
    }

    .e(menu, {
        display: flex;
        justify-content: center;
        list-style: none;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        color: #fff;
    });

    .e(menu-item, {
        padding: 0 19px;
    });

    .e(btn-toggle, {
        display: none;
        color: #fff;
        position: relative;
        left: 18px;

        svg {
            margin: 0;
        }
    });

    .e(right, {
        margin-left: auto;
    });

    .e(wallet-address, {
        background-color: @mf-bg-2;
        border-color: transparent;
        border-radius: 5px;
        padding: 7px 14px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #fff;

        &:hover, &:focus {
            color: #fff;
            background-color: @mf-bg-2;
            border-color: @mf-bg-2;
            box-shadow: 0 0 0 0.2rem @mf-bg-2;
        }

        .ant-image {
            margin-right: 10px;
        }

        .m(mobile, {
            height: 56px;
        });
    });

    @media (min-width: @mf-screen-md-min-tablet) {
        padding: 15px 5%;

        &.is-top {
            padding: 15px 5%;
        }
    }

    @media (max-width: @mf-screen-md-max-tablet) {
        .e(btn-toggle, {
            display: inline-block;
        });

        .e(center, {
            display: none;
        });

        .e(btn-connect, {
            display: none;
        });

        .e(btn-toggle, {
            display: inline-block;
        });

        .e(cta-login, {
            display: none;
        });
    }
}

.canvas-menu {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    transform: translateX(-110%);
    transition: transform 0.2s cubic-bezier(0.66, 0.2, 0.48, 0.9);

    &.is-open {
        transform: translateX(0);
    }

    .e(wrapper, {
        padding: 54px 24px 35px;
        background-color: @mf-bg-1;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    });

    .e(outside, {
        width: 18%;
    });

    .e(logo, {
        width: 145px;
        margin-bottom: 15px;
    });

    .e(cta, {
        margin-top: auto;
    });

    .e(items, {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 700;
    });

    .e(item, {
        padding: 15px 0;
    });

    .e(nav-link, {
        &.active {
            color: @primary-color;
            border-bottom: 2px solid @primary-color;
        }
    });
}
.text-flex {
    display: flex;
    justify-content: space-between;
}
.connect-more-wallets {
    color: #a16bd8;
    cursor: pointer;
    &:hover {
        opacity: 0.6;
    }
}


@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;