
                        @import "src/assets/less/variables.less";
                    
.valentine-wrapper {
    padding: 180px 0 240px;
    background: url(../../../assets/images/valentine/bg.png) no-repeat;
    background-size: cover;
    height: 100%;

    .section-shape {
        z-index: 0;
    }
    .section-shape-beast-1 {
        top: 40%;
        left: 5%;

        img {
            scale: 1;
        }
    }
    .section-shape-beauty-1 {
        top: 30%;
        right: 10%;
        left: unset;

        img {
            scale: 1;
            transform: rotate(0);
        }
    }
    .section-shape-beauty-2 {
        top: 45%;
        right: 5%;

        img {
            scale: 1;
            transform: rotate(0);
        }
    }
    .section-shape-mfg-1 {
        top: 18%;
        left: 10%;
    }
    .section-shape-mfg-2 {
        top: 11%;
        right: 17%;
    }
    .section-shape-mfr-1 {
        top: 35%;
        left: 12%;

        img {
            scale: 1.3;
            transform: rotate(0);
        }
    }
    .section-shape-mfr-2 {
        top: 38%;
        right: 7%;
    }
}
.footer-valentine {
    text-align: center;
    padding-top: 80px;
    .footer__socials {
        a {
            background-color: #ffffff;
            color: #280748;
        }
    }
    .footer__contact {
        a {
            font-size: 20px;
            text-transform: uppercase;
        }
    }
    .footer__contact a,
    .footer__copyright {
        color: #280748;
    }
    .footer__contact {
        svg path {
            fill: #280748;
        }
    }
    .footer__social:hover {
        box-shadow: 0 0 0 0.2rem #f53770;
    }
}
@media (max-width: 1200px) {
    .valentine-wrapper {
        padding: 120px 0;
    }
}
@media (max-width: 1140px) {
    .valentine-wrapper {
        .section-shape-beast-1,
        .section-shape-beauty-1 {
            display: none;
        }
    }
}
@media (max-width: 996px) {
    .valentine-wrapper {
        padding: 110px 10px 300px;
    }
}
// @media (max-width: 768px) {
//     .valentine-wrapper {
//         padding: 100px 0 150px;
//     }
// }

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;