
                        @import "src/assets/less/variables.less";
                    
.mf-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 24px;

    @media (min-width: 576px) {
        max-width: 576px;
    }

    @media (min-width: 768px) {
        max-width: 768px;
    }

    @media (min-width: 992px) {
        max-width: 992px;
    }

    @media (min-width: 1200px) {
        max-width: 1169px;
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;