
                        @import "src/assets/less/variables.less";
                    
.ant-modal {
    font-size: 18px;

    .ant-modal-body {
        font-size: 18px;
    }
}
.no-padding {
    .ant-modal-body {
        padding: 0;
    }
}
.mf-modal {
    //background-color: #262c4a96;
    background: rgba(2, 7, 34, 0.5);

    .mf-modal-content {
        width: 585px;
        // max-height: 800px;
        // overflow-y: auto;
        padding-right: 15px;
        //top: 20%;

        .ant-modal-content {
            background: #1c0532;
            color: #ffffff;
            box-shadow: 0 4px 80px 0 rgba(161, 107, 216, 0.7);

            @border: 2px;
            position: relative;
            background-clip: padding-box;
            border: solid @border transparent;
            border-radius: 10px;
            padding: 0 5px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: -1;
                margin: -@border;
                border-radius: inherit;
                background: linear-gradient(to right, #4ccbc9, #e4007b);
            }

            .ant-modal-body {
                .evm-wallet-item {
                    font-size: 22px;
                    align-items: center;
                    background-color: #2a1143;
                    border: 2px solid #51356e;
                    border-radius: 8px;
                    cursor: pointer;
                    display: flex;
                    margin: 15px 0;
                    padding: 10px 15px;
                    .wallet-logo {
                        margin-right: 8px;
                    }
                    .wallet-title {
                        display: flex;
                        flex-basis: 300px;
                        flex-grow: 1;
                        flex-shrink: 1;
                        margin-bottom: 4px;
                        text-transform: none;
                    }
                    .wallet-install-btn {
                        display: flex;
                        text-transform: none;
                        font-size: 16px;
                        font-weight: 700;
                        color: @mf-primary-color;
                    }
                    .text-green-500 {
                        --tw-text-opacity: 1;
                        color: rgb(14 159 110 / var(--tw-text-opacity));
                    }
                }
            }

            .ant-modal-close {
                position: absolute;
                width: 32px;
                height: 32px;
                line-height: 32px;
                top: -50px;
                color: #ffffff;
                right: 50%;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 10px;
                border: none;
                .ant-modal-close-x {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                }
            }

            .ant-modal-header {
                text-align: center;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background-color: #1c0532;
                border-bottom: 1px solid #1c0532;

                .ant-modal-title {
                    font-family: "Race Sport", serif;
                    color: #ffffff;
                    font-weight: bold;
                    font-size: 20px;
                    padding-top: 36px;
                }
            }

            .modal-body-row {
                padding: 16px 22px;
                margin: 15px 0;
                background-color: #2a1143;
                border-radius: 7px;

                .modal-body-row-title {
                    color: #a8adc3;
                    font-size: 16px;
                }
            }

            .ant-modal-footer {
                border-top: 1px solid #151a30;
                padding: 15px 20px;
                .flex {
                    display: flex;
                    .w-1\/2 {
                        width: 50%;
                    }
                    .button {
                        color: #fff;
                        border-radius: 5px;
                        line-height: 22px;
                        min-height: 44px;
                        font-size: 16px;
                        font-weight: 800;
                        letter-spacing: 0;
                        cursor: pointer;
                        transition: 0.2s ease-in-out;
                        &:hover {
                            transform: translateY(-2px);
                        }
                        & + button {
                            margin-left: 15px;
                        }
                    }
                    .button-dark {
                        background: hsla(0, 0%, 100%, 0.2);
                        border: 2px solid hsla(0, 0%, 100%, 0.2);
                        padding: 0 var(--button-hoz-padding);
                    }
                    .button-secondary {
                        border: #e4007b;
                        background-color: #e4007b;
                    }
                }
                .w-full {
                    width: 100%;
                }
            }
        }
    }
}
.mf-background-modal {
    .mf-modal-content {
        .ant-modal-content {
            color: #ffffff;
            // box-shadow: 0 4px 80px 0 rgba(161, 107, 216, 0.7);

            .ant-modal-close {
                position: absolute;
                width: 32px;
                height: 32px;
                line-height: 32px;
                top: -50px;
                color: #ffffff;
                right: 50%;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 10px;
                border: none;
                .ant-modal-close-x {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .mf-modal {
        .mf-modal-content {
            width: calc(100vw - 25px) !important;
            padding-right: 0;
            //top: 10%;

            .ant-modal-close {
                padding-right: 0 !important;
            }

            .ant-modal-header,
            .ant-modal-footer {
                padding-left: 15px !important;
                padding-right: 15px !important;
            }

            .ant-modal-body {
                padding: 20px 10px;
            }
        }
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;