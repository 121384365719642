
                        @import "src/assets/less/variables.less";
                    
.lunar-wrapper {
    margin-top: 40px;
    position: relative;
    padding: 120px 0 0;
    background: url(../../../assets/images/lunar/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .leave {
        position: absolute;
        bottom: -220px;
        img {
            width: 100%;
            max-width: 630px;
        }
        &.leave-left {
            left: 0;
        }
        &.leave-right {
            right: 0;
        }
    }
    .section-shape {
        z-index: 0;
    }
    .section-shape-beast-1 {
        top: 10%;
        left: 7%;

        img {
            scale: 1;
        }
    }
    .section-shape-beauty-1 {
        top: 35%;
        right: 7%;
        left: unset;

        img {
            scale: 1;
            transform: rotate(0);
        }
    }
    .section-shape-beauty-2 {
        top: 54%;
        right: 7%;

        img {
            scale: 1;
            transform: rotate(0);
        }
    }
    .section-shape-mfg-1 {
        top: 15%;
        left: 13%;
    }
    .section-shape-mfg-2 {
        top: 9%;
        right: 15%;
    }
    .section-shape-mfr-1 {
        top: 50%;
        left: 8%;

        img {
            scale: 1.3;
            transform: rotate(0);
        }
    }
    .section-shape-mfr-2 {
        top: 32%;
        right: 3%;
    }
}
.footer-lunar {
    text-align: center;
    padding-top: 220px;
    padding-bottom: 80px;
    .footer__socials {
        a {
            background-color: #551F00;
            color: #ffffff;
            svg path {
                fill: #ffffff;
            }
        }
    }
    .footer__contact {
        a {
            color: #551F00;
            font-family: Darker Grotesque;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; /* 130% */
            text-decoration-line: underline;
            text-transform: uppercase;
        }
    }
    .footer__contact a,
    .footer__copyright {
        color: #551F00;
    }
    .footer__contact {
        svg path {
            fill: #551F00;
        }
    }
}
@media (max-width: 1200px) {
    .lunar-wrapper {
        padding: 170px 0 220px;
        .leave {
            display: none;
        }
    }
}
@media (max-width: 1140px) {
    .lunar-wrapper {
        .section-shape-beast-1,
        .section-shape-beauty-1,
        .section-shape-mfg-2 {
            display: none;
        }
    }
}
@media (max-width: 996px) {
    .lunar-wrapper {
        padding: 150px 10px 220px;
    }
}
// @media (max-width: 768px) {
//     .summer-wrapper {
//         padding: 100px 0 150px;
//     }
// }

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;