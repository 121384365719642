
                        @import "src/assets/less/variables.less";
                    
.calendar-container {
    display: block;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1170px;
    z-index: 0;
    .title {
        text-align: center;
        margin-bottom: 40px;
        h2 {
            margin-bottom: 15px;
            opacity: 0.9;
        }
        .sub-title {
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                font-size: 56px;
            }
            img {
                margin-top: -60px;
                &.left {
                    margin-right: 20px;
                }
                &.right {
                    margin-left: 20px;
                }
            }
        }
    }

    .event-notification {
        h4 {
            font-size: 20px;
            font-weight: 400;
            line-height: 40px;
            margin-bottom: 20px;
        }
        .list-event-notification {
            width: 80%;
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            .notification {
                z-index: 2;
                margin: 0 5px;
                padding: 10px 15px;
                border-radius: 75px;
                background-color: rgba(161, 107, 216, 0.3);
                color: #ffffff;
                p {
                    margin: 0;
                    span {
                        width: 24px;
                        height: 24px;
                        line-height: 22px;
                        padding-right: 1px;
                        display: inline-block;
                        margin-right: 10px;
                        background-color: #e4007b;
                        border-radius: 50%;
                        position: relative;
                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            background-color: rgba(#e4007b, 0.6);
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            animation: live 1.5s ease-in-out infinite;
                            z-index: -1;
                        }
                    }
                }
            }
        }
        @keyframes live {
            0% {
                transform: scale(1, 1);
            }
            100% {
                transform: scale(1.8, 1.8);
                background-color: rgba(#e4007b, 0);
            }
        }
    }

    .calendar {
        display: block;
        justify-content: center;
        align-items: center;
        padding: 3px;
        border-radius: 20px;
        color: #ffffff;
        position: relative;
        border: 0px solid transparent;
        background-clip: padding-box;
        background-image: linear-gradient(to right bottom, #e4007b, #4cbfcb);
        box-shadow: 0 4px 80px 0 rgb(161 107 216 / 70%);
        .calendar-border {
            width: 100%;
            height: 100%;
            background-color: #4b0094;
            border-radius: 20px;
            padding: 50px 70px;
            h4 {
                color: #ffffff;
            }
        }

        .month {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            font-weight: 300;
            .task-countdown {
                display: flex;
                align-items: center;
                padding-top: 3px;
            }
            .year {
                font-weight: 600;
                margin-left: 10px;
            }

            .nav {
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: #0a3d62;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                transition-duration: 0.2s;
                position: relative;

                &:hover {
                    background: #eee;
                }
            }
        }
        h3 {
            font-size: 28px;
        }
        .days {
            display: grid;
            justify-content: center;
            align-items: center;
            grid-template-columns: repeat(67, 1fr);
            font-size: 16px;
            line-height: 32px;
            margin-bottom: 20px;
            font-weight: 700;
            border-radius: 5px;
            grid-gap: 5px;

            span {
                width: 100%;
                color: #280748;
                background-color: #4ccbc9;
                justify-self: center;
                align-self: center;
                text-align: center;
                border-radius: 5px;
            }
        }

        .dates {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 10px;
            .date-container {
                position: relative;
                .status-icon {
                    cursor: not-allowed;
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    width: 32px;
                    height: 32px;
                }
            }
            .today {
                position: relative;
                padding: 1px;
                @border: 2px;
                border-radius: 7px;
                position: relative;
                background-clip: padding-box;
                border: 0 solid transparent;
                box-shadow: 0 4px 24px 0 #e4007b;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    // z-index: -1;
                    margin: -@border;
                    border-radius: inherit;
                    background: linear-gradient(to right, #4ccbc9, #e4007b);
                }
                .date {
                    &.not_eligible {
                        cursor: pointer;
                        filter: brightness(1) !important;
                    }
                }
            }

            .date {
                padding: 25px 0;
                cursor: pointer;
                position: relative;
                transition-duration: 0.2s;
                width: 100%;
                // height: 100%;
                height: 136px;
                border-radius: 7px;

                &.eligible {
                    background: url(../../assets/images/christmas/claim-bg.png) no-repeat !important;
                    background-size: cover !important;
                }

                &.claimed,
                &.not_eligible {
                    filter: brightness(0.6);
                    cursor: not-allowed;
                }

                &.upcoming {
                    &:hover {
                        opacity: 0.8;
                    }
                }

                .task-eligible {
                    text-align: center;
                    .task-eligible-img {
                        img {
                            width: 54px;
                            height: 48px;
                        }
                    }
                    .task-eligible-button {
                        button {
                            margin-top: 8px;
                            height: 32px;
                            width: 96px;
                            font-size: 18px;
                            line-height: 24px;
                            font-weight: 800;
                        }
                        &.loading-button {
                            button {
                                pointer-events: none;
                            }
                        }
                    }
                }

                .task-detail {
                    text-align: center;
                    .rest {
                        p {
                            font-size: 36px;
                            padding-top: 25px;
                        }
                    }
                    .distance {
                        p {
                            padding-top: 14px;
                        }
                    }
                    .run {
                        p {
                            padding-top: 12px;
                        }
                    }
                }

                img {
                    width: 100%;
                    object-fit: cover;
                }
                .date-of-month {
                    right: 10px;
                    top: 3px;
                    position: absolute;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    margin: 2px;
                    font-family: "Ultra Regular", sans-serif;
                    text-shadow: 4px 1px 2px rgba(0, 0, 0, 0.3);
                }
                p {
                    margin: 0;
                    padding: 2px 12px;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;
                }
                // &:first-child {
                //     grid-column: 3;
                // }
            }
        }
    }
}

@media (max-width: 1140px) {
    .calendar-container {
        .event-notification {
            h4 {
                font-size: 18px;
                line-height: 30px;
            }
            .list-event-notification {
                width: 100%;
                display: block;
                margin: 0 auto;
                .notification {
                    margin: 10px 0;
                }
            }
        }
    }
}

@media (max-width: 996px) {
    .calendar-container {
        .title {
            margin-bottom: 30px;
            h2 {
                font-size: 28px;
                margin-bottom: 10px;
            }
            .sub-title {
                h1 {
                    font-size: 40px;
                    line-height: 50px;
                }
                img {
                    margin-top: -50px;
                    &.left {
                        margin-right: 10px;
                    }
                    &.right {
                        margin-left: 10px;
                    }
                }
            }
        }
        .calendar {
            .month {
                display: block;
            }
            .dates {
                grid-template-columns: repeat(4, 1fr);
                .date {
                    .task-detail {
                        text-align: center;
                        .rest {
                            p {
                                font-size: 28px;
                                padding-top: 5px;
                            }
                        }
                        .distance {
                            p {
                                font-size: 18px;
                                padding-top: 30px;
                            }
                        }
                        .run {
                            p {
                                font-size: 18px;
                                padding-top: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .calendar-container {
        .title {
            h2 {
                font-size: 24px;
                margin-bottom: 10px;
            }
            .sub-title {
                h1 {
                    font-size: 36px;
                    line-height: 40px;
                }
                img {
                    margin-top: -50px;
                    &.left {
                        margin-right: 10px;
                    }
                    &.right {
                        margin-left: 10px;
                    }
                }
            }
        }
        .calendar {
            h3 {
                font-size: 24px;
            }
            .month {
                .task-countdown {
                    gap: 10px;
                    h4 {
                        font-size: 10px;
                    }
                }
            }
            .calendar-border {
                padding: 40px;
            }
            .dates {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}

@media (max-width: 576px) {
    .calendar-container {
        .sub-title {
            img {
                display: none;
            }
        }
        .calendar {
            h3 {
                margin-bottom: 0;
                font-size: 16px;
            }
            .month {
                .task-countdown {
                    gap: 5px;
                }
            }
            .calendar-border {
                padding: 20px;
            }
            .dates {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;