
                        @import "src/assets/less/variables.less";
                    
.app {
    position: relative;
}

.stars-container {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow: hidden;

    .e(star, {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: 0 0;
        opacity: .4;
        box-shadow: 583px 1277px #fff, 984px 896px #fff, 263px 1299px #fff, 1394px 1287px #fff, 429px 1714px #fff, 391px 1345px #fff, 1455px 773px #fff, 673px 316px #fff, 1347px 1573px #fff, 1655px 831px #fff, 757px 951px #fff, 337px 1367px #fff, 1979px 1732px #fff, 169px 1104px #fff, 912px 73px #fff, 351px 1874px #fff, 1700px 1788px #fff, 1482px 1417px #fff, 67px 1509px #fff, 1442px 632px #fff, 1135px 1090px #fff, 1330px 598px #fff, 55px 273px #fff, 501px 156px #fff, 159px 538px #fff, 1276px 1505px #fff, 832px 75px #fff, 94px 1063px #fff, 1993px 478px #fff, 135px 1489px #fff, 535px 275px #fff, 1017px 1681px #fff, 1739px 327px #fff, 989px 378px #fff, 336px 936px #fff, 1585px 1104px #fff, 178px 1316px #fff, 783px 453px #fff, 1556px 1918px #fff, 1135px 590px #fff, 1595px 1701px #fff, 739px 475px #fff, 96px 51px #fff, 828px 1091px #fff, 1307px 494px #fff, 1106px 762px #fff, 334px 1473px #fff, 1025px 671px #fff, 874px 547px #fff, 582px 34px #fff, 931px 1517px #fff, 471px 815px #fff, 1740px 605px #fff, 598px 115px #fff, 1490px 1430px #fff, 1686px 1045px #fff, 106px 1657px #fff, 1176px 132px #fff, 1171px 89px #fff, 1235px 1838px #fff, 1524px 809px #fff, 980px 1179px #fff, 1484px 1383px #fff, 448px 1285px #fff, 1385px 1979px #fff, 117px 1133px #fff, 1110px 1006px #fff, 215px 1102px #fff, 1637px 493px #fff, 382px 791px #fff, 242px 204px #fff, 1464px 1509px #fff, 605px 1767px #fff, 1071px 101px #fff, 531px 1359px #fff, 1889px 1752px #fff, 653px 1749px #fff, 1367px 949px #fff, 483px 817px #fff, 1823px 128px #fff, 1481px 147px #fff, 199px 1273px #fff, 520px 250px #fff, 292px 1905px #fff, 312px 831px #fff, 1009px 747px #fff, 5px 602px #fff, 1661px 569px #fff, 346px 1165px #fff, 426px 272px #fff, 1089px 1311px #fff, 43px 616px #fff, 844px 1835px #fff, 1117px 440px #fff, 1693px 1044px #fff, 1527px 1202px #fff, 928px 1641px #fff, 1886px 222px #fff, 354px 498px #fff, 482px 132px #fff;
        -webkit-animation: animStar 150s linear infinite;
        animation: animStar 150s linear infinite;

        &::after {
            content: " ";
            position: absolute;
            top: 2000px;
            width: 3px;
            height: 3px;
            background: 0 0;
            box-shadow: 583px 1277px #fff, 984px 896px #fff, 263px 1299px #fff, 1394px 1287px #fff, 429px 1714px #fff, 391px 1345px #fff, 1455px 773px #fff, 673px 316px #fff, 1347px 1573px #fff, 1655px 831px #fff, 757px 951px #fff, 337px 1367px #fff, 1979px 1732px #fff, 169px 1104px #fff, 912px 73px #fff, 351px 1874px #fff, 1700px 1788px #fff, 1482px 1417px #fff, 67px 1509px #fff, 1442px 632px #fff, 1135px 1090px #fff, 1330px 598px #fff, 55px 273px #fff, 501px 156px #fff, 159px 538px #fff, 1276px 1505px #fff, 832px 75px #fff, 94px 1063px #fff, 1993px 478px #fff, 135px 1489px #fff, 535px 275px #fff, 1017px 1681px #fff, 1739px 327px #fff, 989px 378px #fff, 336px 936px #fff, 1585px 1104px #fff, 178px 1316px #fff, 783px 453px #fff, 1556px 1918px #fff, 1135px 590px #fff, 1595px 1701px #fff, 739px 475px #fff, 96px 51px #fff, 828px 1091px #fff, 1307px 494px #fff, 1106px 762px #fff, 334px 1473px #fff, 1025px 671px #fff, 874px 547px #fff, 582px 34px #fff, 931px 1517px #fff, 471px 815px #fff, 1740px 605px #fff, 598px 115px #fff, 1490px 1430px #fff, 1686px 1045px #fff, 106px 1657px #fff, 1176px 132px #fff, 1171px 89px #fff, 1235px 1838px #fff, 1524px 809px #fff, 980px 1179px #fff, 1484px 1383px #fff, 448px 1285px #fff, 1385px 1979px #fff, 117px 1133px #fff, 1110px 1006px #fff, 215px 1102px #fff, 1637px 493px #fff, 382px 791px #fff, 242px 204px #fff, 1464px 1509px #fff, 605px 1767px #fff, 1071px 101px #fff, 531px 1359px #fff, 1889px 1752px #fff, 653px 1749px #fff, 1367px 949px #fff, 483px 817px #fff, 1823px 128px #fff, 1481px 147px #fff, 199px 1273px #fff, 520px 250px #fff, 292px 1905px #fff, 312px 831px #fff, 1009px 747px #fff, 5px 602px #fff, 1661px 569px #fff, 346px 1165px #fff, 426px 272px #fff, 1089px 1311px #fff, 43px 616px #fff, 844px 1835px #fff, 1117px 440px #fff, 1693px 1044px #fff, 1527px 1202px #fff, 928px 1641px #fff, 1886px 222px #fff, 354px 498px #fff, 482px 132px #fff
        }
    });
}

@-webkit-keyframes animStar {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-2000px);
    }
}

@keyframes animStar {
    from {
        transform: translateY(0px);
    }

    to {
        transform: translateY(-2000px);
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;