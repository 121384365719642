
                        @import "src/assets/less/variables.less";
                    
.events {
    padding-bottom: 120px;
    background-image: url("../../../../assets/images/planet.png");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;

    .e(title, {
        margin-top: 15px;
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 26px;
        text-align: center;
    });

    @media(min-width: @mf-screen-sm-min) {
        padding-bottom: 300px;

        .e(title, {
            font-size: 36px;
            line-height: 41px;
            margin-bottom: 55px;
        })
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;