
                        @import "src/assets/less/variables.less";
                    
.calendar-starfish-container {
    display: block;
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1370px;
    .title {
        text-align: center;
        h2 {
            color: #000000;
            font-size: 46px;
            line-height: 72px;
            font-weight: 700;
            font-family: "Unbounded", sans-serif;
        }
        img {
            width: 100%;
            max-width: 810px;
            margin: 10px 0;
        }
        .sub-title {
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                font-family: "Praise", sans-serif;
                font-size: 96px;
                line-height: 108px;
                color: #4dfff6;
                text-transform: none;
            }
        }
    }

    .event-notification {
        h4 {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-top: 40px;
            margin-bottom: 24px;
            color: #000000;
        }
        .list-event-notification {
            width: 100%;
            display: flex;
            padding: 0 30px;
            margin-bottom: 40px;
            justify-content: center;
            .notify {
                z-index: 2;
                cursor: default;
                margin: 0 5px;
                padding: 7px 15px 12px;
                border-radius: 75px;
                background-color: rgba(123, 200, 255, 0.4);
                color: #000000;
                p {
                    margin: 0;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 700;
                    font-family: "Darker Grotesque", sans-serif;
                    .index {
                        width: 24px;
                        height: 24px;
                        line-height: 20px;
                        padding-right: 1px;
                        display: inline-block;
                        margin-right: 10px;
                        background-color: #069bee;
                        border-radius: 50%;
                        position: relative;
                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            background-color: rgba(#069bee, 0.6);
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            animation: live 1.5s ease-in-out infinite;
                            z-index: -1;
                        }
                    }
                }
            }
        }
        @keyframes live {
            0% {
                transform: scale(1, 1);
            }
            100% {
                transform: scale(1.8, 1.8);
                background-color: rgba(#069bee, 0);
            }
        }
    }

    .calendar {
        max-width: 1170px;
        display: block;
        margin: 0 auto;
        padding: 3px;
        border-radius: 20px;
        color: #000000;
        position: relative;
        border: 0px solid transparent;
        background-clip: padding-box;
        background-image: linear-gradient(125deg, #069bee 0%, #ff85f3 100%);
        box-shadow: 0px 4px 80px rgba(255, 255, 255, 0.3);
        .calendar-border {
            width: 100%;
            height: 100%;
            background-color: #83d2fe;
            border-radius: 18px;
            padding: 20px 50px 50px;
            h4 {
                color: #ffffff;
            }
        }

        .month {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            font-weight: 300;
            margin-bottom: 20px;
            .text-starfish {
                padding-top: 10px;
                color: #ffffff;
                font-weight: 400;
                font-size: 24px;
                line-height: 40px;
            }
            .task-countdown {
                display: flex;
                align-items: center;
                .count-text {
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    margin-right: 10px;
                    text-transform: uppercase;
                }
                .starfish-event {
                    .countdown {
                        margin: 0 5px;
                        background-color: transparent;
                        .countdown-number {
                            color: #da6ec8;
                        }
                    }
                }
            }
            .year {
                font-weight: 600;
                margin-left: 10px;
            }

            .nav {
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: #0a3d62;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                transition-duration: 0.2s;
                position: relative;

                &:hover {
                    background: #eee;
                }
            }
        }
        h3 {
            font-size: 28px;
        }
        .days {
            display: grid;
            justify-content: center;
            align-items: center;
            grid-template-columns: repeat(7, 1fr);
            font-size: 16px;
            line-height: 32px;
            margin-bottom: 20px;
            font-weight: 700;
            border-radius: 5px;
            grid-gap: 5px;

            span {
                width: 100%;
                color: #280748;
                background-color: #4ccbc9;
                justify-self: center;
                align-self: center;
                text-align: center;
                border-radius: 5px;
            }
        }
        .weekday {
            margin-bottom: 10px;
            div {
                color: #7c4f4c;
                font-weight: 800;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
            }
        }
        .weekday,
        .dates {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-gap: 10px;
            .date-container {
                position: relative;
                .status-icon {
                    cursor: not-allowed;
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 32px;
                    height: 32px;
                    z-index: 12;
                }
                .task-icon {
                    pointer-events: none;
                    position: absolute;
                    bottom: 8px;
                    left: 14px;
                    width: 32px;
                    height: 32px;
                    z-index: 1;
                }
            }
            .today {
                border-radius: 30px;
                position: relative;
                opacity: 1 !important;
                z-index: 10;
                .date {
                    &.not_eligible {
                        cursor: pointer;
                        opacity: 1;
                        // filter: brightness(1) !important;
                    }
                }
            }

            .date {
                padding: 35px 0;
                cursor: pointer;
                position: relative;
                transition-duration: 0.2s;
                width: 100%;
                max-width: 160px;
                height: 175px;
                border-radius: 30px;
                margin: 0 auto;

                &.eligible {
                    background: url(../../assets/images/summer/claim-bg.png) no-repeat !important;
                    background-size: cover !important;
                }

                &.claimed,
                &.not_eligible {
                    background-color: #fff;
                    opacity: 0.5;
                    cursor: pointer;
                }

                &.upcoming {
                    &:hover {
                        opacity: 0.8;
                    }
                }

                .task-eligible {
                    text-align: center;
                    .task-eligible-img {
                        img {
                            width: 54px;
                            height: 48px;
                        }
                    }
                    .task-eligible-button {
                        button {
                            margin-top: 8px;
                            height: 32px;
                            width: 96px;
                            font-size: 18px;
                            line-height: 24px;
                            font-weight: 800;
                            z-index: 10;
                        }
                        &.loading-button {
                            button {
                                pointer-events: none;
                            }
                        }
                    }
                }

                .task-detail {
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    text-align: center;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 24px;
                    color: #ffffff;
                    .rest {
                        p {
                            font-size: 32px;
                        }
                    }
                }

                img {
                    max-width: 80px;
                    max-height: 80px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    margin-bottom: 12px;
                }
                .date-of-month {
                    right: 10px;
                    top: 3px;
                    position: absolute;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    margin: 2px;
                    font-family: "Ultra Regular", sans-serif;
                    text-shadow: 4px 1px 2px rgba(0, 0, 0, 0.3);
                }
                p {
                    margin: 0;
                    padding: 2px 12px;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;
                }
            }
        }
    }

    .exclusive-badge {
        width: 60%;
        margin: 50px auto 0;
        img {
            width: 100%;
        }
    }
}

@media (max-width: 1140px) {
    .calendar-starfish-container {
        .event-notification {
            h4 {
                font-size: 18px;
                line-height: 30px;
            }
            .list-event-notification {
                width: 100%;
                display: block;
                padding: 0;
                margin: 0 auto;
                .notify {
                    margin: 10px 0;
                    p {
                        font-size: 16px;
                        line-height: 20px;
                        text-align: left;
                    }
                    span {
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 996px) {
    .calendar-starfish-container {
        .title {
            margin-bottom: 30px;
            h2 {
                font-size: 28px;
                margin-bottom: 20px;
                line-height: 46px;
            }
            .sub-title {
                h1 {
                    font-size: 40px;
                    line-height: 50px;
                }
                img {
                    margin-top: -50px;
                    &.left {
                        margin-right: 10px;
                    }
                    &.right {
                        margin-left: 10px;
                    }
                }
            }
        }
        .calendar {
            .month {
                display: block;
                .task-countdown {
                    gap: 10px;
                    padding: 0;
                    background-color: transparent;
                }
            }
            .weekday {
                display: none;
            }
            .dates {
                grid-template-columns: repeat(4, 1fr);
                .date {
                    .task-detail {
                        text-align: center;
                        .rest {
                            p {
                                font-size: 28px;
                            }
                        }
                        .distance {
                            p {
                                font-size: 18px;
                            }
                        }
                        .run {
                            p {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .calendar-starfish-container {
        .title {
            .sub-title {
                h1 {
                    font-size: 36px;
                    line-height: 40px;
                }
                img {
                    margin-top: -50px;
                    &.left {
                        margin-right: 10px;
                    }
                    &.right {
                        margin-left: 10px;
                    }
                }
            }
        }
        .calendar {
            h3 {
                font-size: 24px;
            }
            .calendar-border {
                padding: 40px;
            }
            .dates {
                grid-template-columns: repeat(3, 1fr);
            }
        }
        .exclusive-badge {
            width: 100%;
        }
    }
    // .ant-tooltip-open {
    //     display: none !important;
    // }
}

@media (max-width: 576px) {
    .calendar-starfish-container {
        .title {
            h2 {
                font-size: 24px;
                margin-bottom: 20px;
                line-height: 38px;
            }
        }
        .sub-title {
            img {
                display: none;
            }
        }
        .calendar {
            h3 {
                margin-bottom: 0;
                font-size: 16px;
            }
            .month {
                .text-starfish {
                    font-size: 16px;
                    line-height: 24px;
                }
                .task-countdown {
                    gap: 5px;
                    .count-text {
                        font-size: 12px;
                        margin-right: 5px;
                    }
                    .starfish-event {
                        .countdown {
                            .countdown-number {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .calendar-border {
                padding: 20px;
            }
            .dates {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;