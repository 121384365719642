
                        @import "src/assets/less/variables.less";
                    
.ant-pagination {
    margin-top: 15px;
    color: #a8adc3 !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-transform: none;
    font-size: 16px;
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover {
    opacity: 0.4 !important;
}

.ant-pagination-item-link-icon {
    opacity: 0 !important;
}
.ant-pagination-item-ellipsis {
    font-size: 8px;
    opacity: 1 !important;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item-ellipsis {
    color: #a8adc3 !important;
}

.ant-pagination-item {
    border: none !important;
    background-color: transparent !important;
    a {
        color: #a8adc3 !important;
        font-weight: 700 !important;
    }
}
.ant-pagination-item-active {
    background-color: #4ccbc9 !important;
    border-radius: 50% !important;
    a {
        color: #020722 !important;
        font-weight: 700 !important;
    }
}
@media (max-width: 576px) {
    .ant-pagination {
        font-size: 14px !important;
        .ant-pagination-item {
            min-width: 28px;
            height: 28px;
            // margin-right: 4px;
            line-height: 26px;
        }
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;