
                        @import "src/assets/less/variables.less";
                    
.transaction-bg-wrapper {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../../../../assets/images/clan-battle/transaction-bg.png) no-repeat;
        background-size: cover;
    }
    .footer-battle {
        padding: 100px 0;
        text-align: center;
    }
}
.transaction-container {
    text-align: center;
    margin: auto;
    max-width: 90%;
    padding-top: 0;
    h2 {
        font-weight: 400;
    }
    .transaction-table-container {
        margin-top: 40px;
        padding: 0 35px 30px;
        background-color: #020722;
        border: 2px solid transparent;
        background-clip: padding-box;
        border-radius: 20px;
        position: relative;
        box-shadow: 0 4px 50px rgb(161 107 216 / 50%);
        .ant-table-wrapper {
            width: 100%;
            .ant-table-thead > tr > th,
            .ant-table-tbody > tr > td {
                padding: 10px;
            }
            .ant-table-thead {
                .ant-table-cell {
                    font-size: 14px !important;
                }
            }
            .ant-table-tbody {
                .ant-table-cell {
                    font-size: 20px !important;
                }
            }
        }
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: -1;
            margin: -2px;
            border-radius: inherit;
            background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b);
        }
        .txt-nowrap {
            a {
                margin-left: 5px;
            }
        }
        .ant-table-content {
            overflow-x: auto;
        }
    }
    .btn-refresh {
        border: 1px solid @mf-secondary-color;
        background-color: @mf-secondary-color;
        color: #ffffff;
        height: 36px;
        line-height: 12px;
        img {
            margin-top: -4px;
            filter: invert(100%);
            margin-right: 10px;
            width: 16px;
        }
        &:hover {
            opacity: 0.8;
        }
    }
    .team-column {
        justify-content: start;
        span {
            display: block;
        }
    }
}
@media (max-width: 768px) {
    .transaction-container {
        padding-top: 0px;
        max-width: 90%;
        .team-column {
            justify-content: center;
            span {
                display: none;
            }
        }
    }
}
@media (max-width: 576px) {
    .transaction-container {
        text-align: left;
        .transaction-table-container {
            margin-top: 20px;
        }
        h2 {
            font-size: 22px;
            line-height: 32px;
        }
    }
    .ant-table {
        table {
            font-size: 12px;
            .ant-table-thead > tr > th,
            .ant-table-tbody > tr > td {
                padding: 5px;
            }
            h4 {
                font-size: 12px;
            }
        }
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;