
                        @import "src/assets/less/variables.less";
                    
.ntfs-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;

    .e(item, {
       width: calc(50% - 10px);

        .ntfs-card {
            height: 100%;
            display: flex;
        }
    });

    @media(min-width: @mf-screen-sm-min) {
        gap: 30px;

        .e(item, {
           width: calc(20% - 30px);
        });
    }
}

.ntfs-empty {
    text-align: center;

    .e(text, {
        color: #fff;
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        margin-bottom: 20px;
    });

    .e(btn, {
       &:not(:last-child) {
           margin-bottom: 16px;
       }
    });

    @media(min-width: @mf-screen-sm-min) {
        font-size: 32px;
        line-height: 40px;
        width: 584px;
        max-width: 100%;
        margin: auto;

        .e(btn, {
            &.ant-btn.ant-btn-block {
                width: initial;
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }
        });

        .e(cta, {
            display: flex;
            justify-content: center;
            gap: 20px;
        })
    }
}

.nfts-load-more {
    margin-top: 50px;
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;