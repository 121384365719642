
                        @import "src/assets/less/variables.less";
                    
.modal-reward-token-container,
.modal-reward-item-container {
    .ant-modal-content {
        &::before {
            top: -2px !important;
            right: -1px !important;
            bottom: -2px !important;
            left: 0px !important;
            margin: -2px !important;
        }
        .task-reward {
            text-align: center;
            h1 {
                padding: 70px;
                font-weight: 400;
                font-size: 142px;
                line-height: 80px;
            }
            img {
                padding: 50px;
            }
            h1,
            h2 {
                margin-bottom: 0;
            }
            h2 {
                font-weight: 400;
                font-size: 56px;
                line-height: 56px;
            }
            h3 {
                font-weight: 400;
                font-size: 42px;
                line-height: 42px;
            }
            .button-container {
                margin-top: 30px;
                button {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .modal-reward-token-container,
    .modal-reward-item-container {
        .ant-modal-content {
            &::before {
                top: -1px !important;
                right: -3px !important;
                bottom: -1px !important;
                left: -3px !important;
            }
        }
    }
}

.modal-reward-token-container {
    .ant-modal-content {
        background: url(../../../assets/images/christmas/reward-bg.jpg) no-repeat center !important;
        padding: 50px 0 0 !important;
        background-size: cover !important;
    }
}

.modal-reward-item-container {
    .ant-modal-content {
        background: url(../../../assets/images/christmas/reward-bg-2.jpg) no-repeat center !important;
        padding: 50px 0 0 !important;
        background-size: cover !important;
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;