body {
    background-image: url("../../assets/images/galaxy.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    z-index: 10;
    &.toggle-menu {
        overflow: hidden;
    }
}

.h-link {
    position: relative;
    text-decoration: none;

    &::after {
        background: none repeat scroll 0 0 currentColor;
        bottom: 0;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
    }

    &:hover::after {
        width: 100%;
        left: 0;
    }
}
.message-success {
    .ant-message-notice-content {
        border-radius: 10px;
        background-color: #a5d990;
        color: #fff;
        .anticon {
            display: none;
        }
    }
}
.message-error {
    .ant-message-notice-content {
        border-radius: 10px;
        background-color: #ef2763;
        color: #fff;
        .anticon {
            display: none;
        }
    }
}
.walletconnect-modal__base {
    a {
        color: #222;
    }
}

.section-shape {
    position: absolute;
    user-select: none;
    pointer-events: none;
}

.text-primary {
    color: @mf-primary-color !important;
}
.text-secondary {
    color: @mf-primary-color-2;
}
.text-green {
    color: #a5d990;
}
/* Firefox */

* {
    scrollbar-width: thin;
    scrollbar-color: #141414 #2c2c2c;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #2c2c2c;
}

*::-webkit-scrollbar-thumb {
    background-color: #141414;
    border-radius: 8px;
    border: 2px solid #1c1c1c;
}
.move-vertical {
    animation: move-vertical 5s linear infinite;
}

.move-vertical-reversed {
    animation: move-vertical-reversed 5s linear infinite;
}

@keyframes move-vertical {
    0% {
        transform: translate(0);
    }
    25% {
        transform: translate(0, 10px);
    }
    50% {
        transform: translate(0, 5px);
    }
    75% {
        transform: translate(0, -5px);
    }
    to {
        transform: translate(0);
    }
}

@keyframes move-vertical-reversed {
    0% {
        transform: translate(0);
    }
    25% {
        transform: translate(0, -10px);
    }
    50% {
        transform: translate(0, -5px);
    }
    75% {
        transform: translate(0, 5px);
    }
    to {
        transform: translate(0);
    }
}
.p-0 {
    padding: 0;
}
.m-0 {
    margin: 0;
}
.mb-5 {
    margin-bottom: 1.25rem;
}
.font-race-sport {
    font-family: "Race Sport", sans-serif;
    text-transform: uppercase;
}
.font-darker-grotesque {
    font-family: "Darker Grotesque", sans-serif;
}
.txt-nowrap {
    white-space: nowrap;
}
.d-flex {
    display: flex !important;
}
.align-items-center {
    align-items: center;
}
.justify-content-center {
    justify-content: center;
}
.jden-icon {
    margin-top: 5px;
}
.color-grey {
    color: #a8adc3 !important;
}
.text-center {
    text-align: center;
}
.mintpass-img {
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 50%;
    margin-right: 5px;
}
.ant-btn {
    &:disabled {
        opacity: 0.5;
    }
}
.text-golden {
    color: #ffa100;
    background-image: -webkit-linear-gradient(0deg, #ffa100 0%, #ffd970 80%, #ff9c00 100%);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}
.text-golden-shadow {
    // text-shadow: 0px -2px 2px #ffdf94;
    filter: drop-shadow(0px -2px #ffdf94);
}
.text-triple-gradient {
    z-index: 2;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    background-image: linear-gradient(
        to right bottom,
        #4cbfcb,
        #7ac9e0,
        #a3d4ed,
        #c7dff5,
        #e4ebfa,
        #e7eafd,
        #ece8ff,
        #f2e6ff,
        #efd1fe,
        #f2baf8,
        #f7a1ed,
        #ff84dd
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    filter: drop-shadow(-2px 3px #1749ff);
}
.text-pink-glow {
    color: #ffffff;
    text-shadow: rgb(253, 16, 144) 5px 0px 0px, rgb(253, 16, 144) 4.90033px 0.993347px 0px,
        rgb(253, 16, 144) 4.60531px 1.94709px 0px, rgb(253, 16, 144) 4.12668px 2.82321px 0px,
        rgb(253, 16, 144) 3.48353px 3.58678px 0px, rgb(253, 16, 144) 2.70151px 4.20736px 0px,
        rgb(253, 16, 144) 1.81179px 4.6602px 0px, rgb(253, 16, 144) 0.849836px 4.92725px 0px,
        rgb(253, 16, 144) -0.145998px 4.99787px 0px, rgb(253, 16, 144) -1.13601px 4.86924px 0px,
        rgb(253, 16, 144) -2.08073px 4.54649px 0px, rgb(253, 16, 144) -2.94251px 4.04248px 0px,
        rgb(253, 16, 144) -3.68697px 3.37732px 0px, rgb(253, 16, 144) -4.28444px 2.57751px 0px,
        rgb(253, 16, 144) -4.71111px 1.67494px 0px, rgb(253, 16, 144) -4.94996px 0.7056px 0px,
        rgb(253, 16, 144) -4.99147px -0.291871px 0px, rgb(253, 16, 144) -4.83399px -1.27771px 0px,
        rgb(253, 16, 144) -4.48379px -2.2126px 0px, rgb(253, 16, 144) -3.95484px -3.05929px 0px,
        rgb(253, 16, 144) -3.26822px -3.78401px 0px, rgb(253, 16, 144) -2.4513px -4.35788px 0px,
        rgb(253, 16, 144) -1.53666px -4.75801px 0px, rgb(253, 16, 144) -0.560763px -4.96845px 0px,
        rgb(253, 16, 144) 0.437495px -4.98082px 0px, rgb(253, 16, 144) 1.41831px -4.79462px 0px,
        rgb(253, 16, 144) 2.34258px -4.41727px 0px, rgb(253, 16, 144) 3.17346px -3.86382px 0px,
        rgb(253, 16, 144) 3.87783px -3.15633px 0px, rgb(253, 16, 144) 4.4276px -2.32301px 0px,
        rgb(253, 16, 144) 4.80085px -1.39708px 0px, rgb(253, 16, 144) 4.98271px -0.415447px 2px;
}
.text-pink-glow-thin {
    color: #ffffff;
    text-shadow: rgb(228, 0, 123) 3px 0px 0px, rgb(228, 0, 123) 2.83487px 0.981584px 0px,
        rgb(228, 0, 123) 2.35766px 1.85511px 0px, rgb(228, 0, 123) 1.62091px 2.52441px 0px,
        rgb(228, 0, 123) 0.705713px 2.91581px 0px, rgb(228, 0, 123) -0.287171px 2.98622px 0px,
        rgb(228, 0, 123) -1.24844px 2.72789px 0px, rgb(228, 0, 123) -2.07227px 2.16926px 0px,
        rgb(228, 0, 123) -2.66798px 1.37182px 0px, rgb(228, 0, 123) -2.96998px 0.42336px 0px,
        rgb(228, 0, 123) -2.94502px -0.571704px 0px, rgb(228, 0, 123) -2.59586px -1.50383px 0px,
        rgb(228, 0, 123) -1.96093px -2.27041px 0px, rgb(228, 0, 123) -1.11013px -2.78704px 0px,
        rgb(228, 0, 123) -0.137119px -2.99686px 0px, rgb(228, 0, 123) 0.850987px -2.87677px 0px,
        rgb(228, 0, 123) 1.74541px -2.43999px 0px, rgb(228, 0, 123) 2.44769px -1.73459px 0px,
        rgb(228, 0, 123) 2.88051px -0.838247px 0px;
}
.text-green-glow-thin {
    color: #ffffff;
    text-shadow: #4ccbc9 3px 0px 0px, #4ccbc9 2.83487px 0.981584px 0px, #4ccbc9 2.35766px 1.85511px 0px,
        #4ccbc9 1.62091px 2.52441px 0px, #4ccbc9 0.705713px 2.91581px 0px, #4ccbc9 -0.287171px 2.98622px 0px,
        #4ccbc9 -1.24844px 2.72789px 0px, #4ccbc9 -2.07227px 2.16926px 0px, #4ccbc9 -2.66798px 1.37182px 0px,
        #4ccbc9 -2.96998px 0.42336px 0px, #4ccbc9 -2.94502px -0.571704px 0px, #4ccbc9 -2.59586px -1.50383px 0px,
        #4ccbc9 -1.96093px -2.27041px 0px, #4ccbc9 -1.11013px -2.78704px 0px, #4ccbc9 -0.137119px -2.99686px 0px,
        #4ccbc9 0.850987px -2.87677px 0px, #4ccbc9 1.74541px -2.43999px 0px, #4ccbc9 2.44769px -1.73459px 0px,
        #4ccbc9 2.88051px -0.838247px 0px;
}
.text-triple-gradient-2 {
    color: #ff84dd;
    background-image: -webkit-linear-gradient(90deg, #ff84dd 0%, #ffffff 50%, #00f0ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    filter: drop-shadow(-2px 3px #1749ff);
}
.text-triple-gradient-3 {
    color: #fff;
    background-image: -webkit-linear-gradient(90deg, #71c1b3 0%, #ffffff 50%, #f53770 100%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-stroke: 6px transparent;
}
.ant-spin-blur::after {
    opacity: 0 !important;
}
.ant-notification-notice {
    border: 2px solid transparent !important;
    background-clip: padding-box !important;
    padding: 2px !important;
    border-radius: 6px !important;
    background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b) !important;
    .ant-notification-notice-message {
        display: none;
    }
    .ant-notification-notice-content {
        width: 100%;
        height: 100%;
        background-color: #250e3a;
        border-radius: 4px;
        padding: 10px;
    }
    .anticon-check-circle {
        display: none;
    }
    .ant-notification-notice-description {
        margin-left: 0 !important;
    }
    .ant-notification-notice-close {
        color: #fff;
    }
    .notification {
        display: flex;
        align-items: center;
        color: #fff;
        .notification-content {
            margin-left: 10px;
            p {
                font-size: 16px;
                margin: 0;
                &.user {
                    font-size: 20px;
                }
            }
            .amount {
                display: flex;
                align-items: center;
                h4 {
                    margin-top: 5px;
                    margin-bottom: 0;
                }
                .dot {
                    margin: 0 5px;
                    width: 4px;
                    height: 4px;
                    opacity: 0.5;
                    border-radius: 50%;
                    background-color: #a8adc3;
                }
            }
        }
    }
}
.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.6) !important;
}