
                        @import "src/assets/less/variables.less";
                    
.sticky-reward {
    position: sticky;
    position: -webkit-sticky;
    top: 110px;
    .btn-claim {
        .disabled {
            img {
                margin-top: -5px;
            }
            color: #ffffff;
            background-color: #73034e;
            border: #73034e;
        }
    }
    .btn-outside-event {
        margin-top: 20px;
    }
    .claim-count {
        font-size: 20px;
        .total-count {
            color: #ffffff;
            font-weight: 600;
        }
    }
    &.outside-event {
        top: 130px;
    }
}
.reward-token-box,
.reward-product-box,
.reward-nft-box {
    padding: 20px 15px;
    border-radius: 10px;
    background-color: @mf-bg-2;
}
.reward-nft-box {
    padding: 0;
    display: row flex;
    width: 100%;
    .box-header {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: @mf-bg-4;
        padding: 10px 15px 5px 15px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    h4 {
        padding-top: 3px;
    }
    .box-mint-pass {
        width: 100%;
        display: block;
        text-align: center;
        padding: 30px 0;
        background-color: @mf-bg-2;
        img {
            border-radius: 5px;
            width: 156px;
            height: 156px;
        }
    }
    .box-contract {
        width: 100%;
        padding: 10px 15px 15px 15px;
        background-color: @mf-bg-3;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        .pass-contract {
            margin-bottom: 0;
        }
        .wallet-address {
            color: #ffffff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .btn-copy {
                cursor: pointer;
                margin: auto 0;
                .anticon-copy {
                    color: @mf-primary-color;
                }
            }
        }
        .block-explorer {
            margin-top: 10px;
            color: @mf-secondary-color;
        }
    }
}
.reward-token-box {
    span {
        color: #ffffff;
    }
    h3 {
        color: @mf-primary-color;
    }
    span,
    h3 {
        margin: auto 0;
    }
}
.reward-product-box {
    padding: 20px 15px 0px 15px;
    a {
        color: @mf-primary-color;
    }
    p {
        margin-bottom: 0.3em;
    }
    .white-bold {
        color: #ffffff;
        font-weight: bold;
    }
    .size-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        input {
            display: none;
        }
        .button {
            display: inline-block;
            position: relative;
            width: 52px;
            height: 36px;
            margin: 0px 5px 5px 0;
            padding: 10px 5px 10px 15px;
            cursor: pointer;
            span {
                text-align: center;
                display: block;
                position: absolute;
                width: 52px;
                height: 36px;
                padding: 4px 0;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                border-radius: 5px;
                background-color: #21063c;
                &:hover {
                    width: 55px;
                    height: 39px;
                }
                &.active {
                    padding: 2px 0 !important;
                    border: 2px solid @mf-primary-color;
                    color: #ffffff;
                }
            }
        }
    }
    .color-list {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        input {
            display: none;
        }
        .button {
            display: inline-block;
            position: relative;
            width: 36px;
            height: 36px;
            margin: 0px 10px 10px 0;
            padding: 10px 5px 10px 15px;
            cursor: pointer;
            span {
                display: block;
                position: absolute;
                width: 36px;
                height: 36px;
                padding: 0;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                border-radius: 100%;
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
                &:hover {
                    padding: 4px;
                }
                .anticon-check {
                    padding-top: 5px;
                    color: #ffffff;
                }
            }
            .active {
                position: absolute;
                border-radius: 50%;
                border: 3px solid #4ccbc9;
                width: 36px;
                height: 36px;
                position: absolute;
                top: 0;
                right: -5%;
                span {
                    width: 26px;
                    height: 26px;
                }
            }
            &.white {
                .anticon-check {
                    color: #222222;
                }
            }
        }
    }
}
.size-guide-modal {
    .ant-modal-body {
        padding: 0;
    }
}
.checkout-modal {
    .ant-modal-close {
        position: absolute;
        width: 32px;
        height: 32px;
        line-height: 32px;
        top: -50px;
        color: #ffffff;
        right: 47%;
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.2);
        .ant-modal-close-x {
            width: 32px;
            height: 32px;
            padding-right: 3px;
            line-height: 32px;
        }
    }
    .ant-modal-content {
        background-color: @mf-bg-2;
        border-radius: 10px;
        border: 2px solid transparent;
        background-clip: padding-box;
        box-shadow: 0 4px 80px 0 rgb(161 107 216 / 70%);
        &::after {
            background: linear-gradient(90deg, #4ccbc9, #e4007b);
            border-radius: inherit;
            bottom: 0;
            content: "";
            left: 0;
            margin: -3px -4px -3px -3px;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
        }
    }
    @media (max-width: 768px) {
        .ant-modal-content {
            width: 90%;
            margin: auto;
        }
    }
}
.ant-image-img {
    border-radius: 10px;
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;