
                        @import "src/assets/less/variables.less";
                    
.btn-claim {
    margin: 20px 0;
    button {
        width: 100%;
    }
}
.description {
    margin-bottom: 20px;
    ul {
        padding: 0;
        list-style-type: none;
        .anticon-arrow-right {
            font-size: 14px;
            color: @mf-primary-color;
        }
    }
}
.token-reward {
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    // background: radial-gradient(#9b115c, #451487);
    // background: repeating-conic-gradient(#21063c 6deg 15deg, #451487 0deg 25deg);
    img {
        border-radius: 10px;
        width: 100%;
        position: relative;
    }
    span {
        position: absolute;
        font-size: 54px;
        font-family: "Race Sport", sans-serif;
        color: @mf-primary-color-2;
    }
}
.entry-count {
    color: @mf-primary-color;
}
.list-rewards {
    img {
        border-radius: 10px;
    }
    .nft-reward {
        img {
            width: 350px;
            height: 350px;
            background: radial-gradient(#623f84, #220e36);
        }
    }
    .mint-pass-title {
        color: #ffffff;
        font-size: 32px;
    }
    a {
        color: @mf-secondary-color;
    }
    .small-reward {
        align-content: space-between;
        img {
            width: 230px;
            height: 230px;
        }
        .reward2 {
            margin-bottom: 20px;
        }
    }
    .large-reward {
        img {
            width: 490px;
            height: 490px;
        }
    }
}
.carousel-img {
    .ant-image {
        width: 100%;
    }
}
.eligible-nfts {
    margin-bottom: 40px;
    .nft-detail {
        cursor: pointer;
        .nft-mint-pass {
            text-align: center;
            margin-top: 10px;
            h4 {
                margin-bottom: 0;
            }
        }
        .nft-image {
            img {
                object-fit: cover;
                max-height: 235px;
                padding: 5px;
                border-radius: 5px;
                width: 100%;
            }
            &.active {
                box-shadow: 0 4px 80px 0 rgb(161 107 216 / 70%);
                margin: -3px;
                border: 1px solid;
                border-image: linear-gradient(45deg, #4ccbc9, #e4007b) 1;
            }
        }
    }
}
.list-entries {
    padding: 0;
    width: 100%;
    a {
        color: #a8adc3;
    }
    .entry-item {
        display: flex;
        flex-wrap: wrap;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 20px;
        background-color: @mf-bg-2;
        justify-content: space-between;
        cursor: pointer;
        .entry-item-left {
            display: flex;
            .entry-point-counter {
                margin: auto 10px auto 0;
                width: 56px;
                height: 48px;
                line-height: 48px;
                border-radius: 5px;
                background-color: #250e3a;
                border: 1px solid #250e3a;
                text-align: center;
                padding-top: 8px;
                h3 {
                    font-size: 16px;
                }
                &.has-point {
                    background-color: #f0489f;
                    border: 1px solid #f0489f;
                }
            }
            .entry-title {
                font-size: 20px;
                margin: auto 0;
                font-weight: 600;
                color: #fff;
                .discord {
                    color: #5865f2;
                }
                .twitter {
                    color: #03a9f4;
                }
                .btn-twitter {
                    margin-left: 10px;
                    color: #ffffff;
                    border: #479ce9;
                    background-color: #479ce9;
                    line-height: initial;
                    padding: 5px 15px 30px 15px;
                    span {
                        margin-left: 0;
                    }
                    .anticon-twitter {
                        vertical-align: 2px;
                    }
                    &:hover {
                        box-shadow: none;
                        opacity: 0.8;
                    }
                }
            }
        }
        .entry-achived {
            width: 86px;
            height: 48px;
            font-size: 22px;
            font-weight: 800;
            text-align: center;
            line-height: 48px;
            border-radius: 5px;
            color: #020722;
            background-color: #4ccbc9;
            margin: auto 0;
            .loader {
                font-size: 24px;
                -webkit-animation: spinLoading 2s linear infinite; /* Safari */
                animation: spinLoading 2s linear infinite;
            }
            @-webkit-keyframes spinLoading {
                0% {
                    -webkit-transform: rotate(360deg);
                }
                100% {
                    -webkit-transform: rotate(0deg);
                }
            }

            @keyframes spinLoading {
                0% {
                    transform: rotate(360deg);
                }
                100% {
                    transform: rotate(0deg);
                }
            }
        }
        .nft {
            line-height: 0;
            padding-top: 12px;
            p,
            span {
                font-size: 16px;
                font-weight: 800;
            }
        }
        // &.checked-hold {
        //     .entry-achived {
        //         color: #280748;
        //         stroke: #280748;
        //         stroke-width: 64px;
        //         background-color: #a5d990;
        //     }
        // }
        &.checked {
            .entry-achived {
                color: #280748;
                stroke: #280748;
                stroke-width: 64px;
                background-color: #a5d990;
            }
        }
        &.checked-daily {
            // pointer-events: none;
            .entry-achived {
                line-height: 44px;
                background-color: #48c8f0;
            }
        }
        &.locked {
            .entry-achived {
                margin: auto 0;
                line-height: 44px;
            }
        }
    }
    .entry-item:has(.entry-achived > .loader) {
        pointer-events: none;
    }
}
.raffle-end {
    width: 100%;
    height: 282px;
    background-color: #351c4f;
    border-radius: 10px;
    .raffle-end-icon {
        margin-top: 30px;
    }
    .raffle-end-text {
        text-align: center;
        h3,
        h4 {
            color: #4ccbc9;
        }
    }
    @media (max-width: 576px) {
        .raffle-end-text {
            h3 {
                font-size: 18px;
            }
            h4 {
                font-size: 14px;
            }
        }
    }
}
@media (min-width: 990px) {
    .token-reward {
        margin-top: 30px;
    }
}
@media (min-width: 1200px) {
    .token-reward {
        margin-top: 0;
    }
}
@media (max-width: 768px) {
    .token-reward {
        margin-top: 0;
        height: auto;
        span {
            font-size: 34px;
        }
    }
    .list-entries {
        .ant-tooltip-inner {
            width: 800px;
        }
        .entry-item {
            .entry-item-left {
                .entry-point-counter {
                    width: 56px;
                    height: 40px;
                    h3 {
                        line-height: 26px;
                    }
                }
                .entry-title {
                    font-size: 16px;
                    max-width: 300px;
                    .btn-twitter {
                        font-size: 16px;
                        margin-left: 0;
                        padding: 2px 8px 25px 8px;
                    }
                }
            }
            .entry-achived {
                width: 62px;
                height: 40px;
                line-height: 40px;
            }
            &.checked-daily,
            &.locked {
                .entry-achived {
                    line-height: 35px;
                }
            }
            .nft {
                line-height: 0;
            }
            &.checked {
                .entry-achived {
                    stroke-width: 44px;
                    background-color: #a5d990;
                }
            }
        }
    }
}
@media (max-width: 480px) {
    .list-entries {
        .entry-item {
            .entry-item-left {
                .entry-title {
                    max-width: 150px;
                }
            }
            .entry-achived {
                list-style: 40px;
            }
            &.checked-daily,
            &.locked {
                .entry-achived {
                    line-height: 35px;
                }
            }
        }
    }
}
@media (max-width: 360px) {
    .list-entries {
        .entry-item {
            .entry-item-left {
                .entry-title {
                    max-width: 120px;
                }
            }
        }
    }
}
.ant-image-preview-operations-operation:not(:first-child),
.ant-image-mask-info {
    display: none;
}
.fcfs {
    .ant-tag {
        width: 54px;
        height: 28px;
        font-size: 16px;
        text-align: center;
        color: #03071d;
        font-weight: bold;
        padding-top: 2px;
    }
}
.btn-load-more {
    margin-top: 20px;
}
@media (max-width: 768px) {
    .raffle-game-title {
        font-size: 16px;
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;