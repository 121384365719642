
                        @import "src/assets/less/variables.less";
                    
.timeline-bg-wrapper {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url(../../../../assets/images/cloud-bg.png),
            url(../../../../assets/images/clan-battle/timeline-bg.png);
        background-repeat: no-repeat, no-repeat;
        background-position: top, bottom;
        opacity: 1;
    }
}
.timeline-container {
    padding: 0 10px;
    margin: 180px 0 0;
    &.modal-timeline {
        margin: 10px 0;
    }
    .btn-all-activities {
        margin-top: 90px;
        button {
            color: #020722;
            font-size: 18px;
            font-weight: 800;
            width: 252px;
            border: 1px solid @mf-primary-color;
            background-color: @mf-primary-color;
        }
    }
}
.timeline {
    position: relative;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 15px 0;
    .ant-avatar {
        margin-right: 10px;
        &.timeline-user-image {
            border: 2px solid transparent;
            background-clip: padding-box;
            padding: 2px;
            background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b);
            img {
                border-radius: 50%;
            }
        }
    }
    .timeline-start {
        margin-top: -40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .timeline-current {
        position: absolute;
        margin-top: 15px;
        left: 44%;
        width: 137px;
        border: 1px solid #a16bd8;
        background-color: #a16bd8;
        border-radius: 40px;
        text-align: center;
        padding: 3px 0 7px;
        span {
            color: #ffffff;
            font-size: 24px;
        }
    }
    .circle {
        display: inline-block;
        position: absolute;
        top: 5px;
        background-color: #e4007b;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(black, 0.1);
        border-radius: 50%;
        z-index: 1;
        &:before {
            content: "";
            display: block;
            position: absolute;
            background-color: rgba(#e4007b, 0.6);
            width: 100%;
            height: 100%;
            border-radius: 50%;
            animation: live 1.5s ease-in-out infinite;
            z-index: -1;
        }
    }

    @keyframes live {
        0% {
            transform: scale(1, 1);
        }
        100% {
            transform: scale(3.5, 3.5);
            background-color: rgba(#e4007b, 0);
        }
    }

    @keyframes live2 {
        0% {
            transform: scale(1, 1);
        }
        100% {
            transform: scale(5, 5);
            background-color: rgba(#e4007b, 0);
        }
    }
}

.timeline::after {
    content: "";
    position: absolute;
    width: 2px;
    background: @mf-primary-color;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
    background-image: radial-gradient(circle, #4ccbc9, #1a96a9, #056381, #093452, #020722);
}

.container {
    position: relative;
    background: inherit;
    width: 50%;
}

.container.left {
    left: 0;
    padding: 120px 100px 15px 0;
}

.container.right {
    left: 50%;
    padding: 120px 0px 15px 100px;
}

.container {
    &.right {
        .dot {
            position: absolute;
            width: 10px;
            height: 10px;
            top: calc(50% + 48px);
            left: -5px;
            background: @mf-primary-color;
            border: 2px solid @mf-primary-color;
            border-radius: 50%;
            z-index: 1;
            &:before {
                content: "";
                display: block;
                position: absolute;
                background-color: rgba(#4cbfcb, 0.6);
                width: 100%;
                height: 100%;
                border-radius: 50%;
                animation: live2 1.4s ease-in-out infinite;
                z-index: -1;
            }
        }
    }
}

.container {
    &.left {
        .dot {
            position: absolute;
            width: 10px;
            height: 10px;
            top: calc(50% + 48px);
            right: -5px;
            background: #e4007b;
            border: 2px solid #e4007b;
            border-radius: 50%;
            z-index: 1;
            &:before {
                content: "";
                display: block;
                position: absolute;
                background-color: rgba(#e4007b, 0.6);
                width: 100%;
                height: 100%;
                border-radius: 50%;
                animation: live2 1.4s ease-in-out infinite;
                z-index: -1;
            }
        }
    }
}

.container::before {
    content: "";
    position: absolute;
    width: 92px;
    height: 2px;
    top: calc(50% + 52px);
    right: 8px;
    background: @mf-primary-color;
    z-index: 1;
}

.container.left::before {
    background: linear-gradient(90deg, rgba(228, 0, 123, 1) 0%, rgba(0, 0, 0, 1) 100%);
}

.container.right::before {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(76, 203, 201, 1) 100%);
}

.container.right::before {
    left: 8px;
}

.container .date {
    position: absolute;
    display: inline-block;
    top: calc(50% + 42px);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 1;
    font-family: "Race Sport";
}

.container.left {
    .content {
        border: 3px @mf-primary-color-2 solid;
        &::before {
            content: "";
            position: absolute;
            background: #e4007b;
            top: 50%;
            right: -1px;
            box-shadow: 0 0 5px 5px #e4007b;
        }
    }
    .date {
        right: -270px;
    }
}

.container.right {
    .content {
        border: 3px @mf-primary-color solid;
        &::before {
            content: "";
            position: absolute;
            background: #00f0ff;
            top: 50%;
            left: -1px;
            box-shadow: 0 0 5px 5px #00f0ff;
        }
    }
    .date {
        left: -270px;
    }
}

.container .content {
    width: 100%;
    padding: 25px 30px;
    border-radius: 10px;
    background: #1c0532;
    position: relative;
    box-shadow: 0 4px 50px rgb(161 107 216 / 50%);
}

.container .content h2 {
    margin: 0 0 5px 0;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    color: #ffffff;
}

.container .content p {
    margin: 0;
    font-size: 24px;
    line-height: 28px;
    color: #a8adc3;
    font-weight: 700;
}
.modal-timeline-container {
    .ant-modal-body {
        height: 700px;
        overflow-y: auto;
        padding: 50px 0;
    }
}

@media (max-width: 996px) {
    .timeline-container {
        margin: 180px 0 80px;
    }

    .timeline::after {
        left: 40px;
    }

    .timeline {
        .timeline-start {
            left: 40px;
            position: absolute !important;
        }

        .timeline-current {
            position: relative;
            margin: 10px auto 0;
            left: 0;
            span {
                font-size: 18px;
            }
        }
    }

    .btn-all-activities {
        margin-top: 20px !important;
        button {
            width: 90%;
        }
    }

    .container {
        width: 100%;
        padding-left: 120px;
        padding-right: 30px;
    }

    .container .content p {
        font-size: 16px;
        line-height: 24px;
    }

    .container::before {
        width: 45px;
    }

    .container.right {
        left: 0%;
        padding: 25px 10px 25px 80px;
        .content {
            border: 2px @mf-primary-color solid;
            p,
            .text-primary {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }

    .container.left {
        padding: 25px 10px 25px 80px;
        .content {
            border: 2px @mf-primary-color-2 solid;
            p,
            .text-primary {
                font-size: 16px;
                line-height: 18px;
            }
            &::before {
                right: unset;
                left: 0;
            }
        }
    }

    .container::after {
        top: calc(50% - 4px);
    }

    .container::before {
        top: calc(50%);
    }

    .container.left::after,
    .container.right::after {
        left: 35px;
    }

    .container.left::before,
    .container.right::before {
        left: 35px;
        border-color: transparent #006e51 transparent transparent;
    }

    .container.left::before {
        background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(228, 0, 123, 1) 0%);
    }

    .container.left .date,
    .container.right .date {
        font-size: 12px;
        font-weight: 400;
        right: auto;
        top: 0px;
        left: 90px;
    }

    .container .content h2 {
        font-size: 16px;
        line-height: 26px;
        margin: 0;
    }

    .container.left .content,
    .container.right .content {
        padding: 15px;
        border-radius: 10px;
        font-size: 16px;
    }
    .modal-timeline-container {
        .ant-modal-body {
            height: auto;
            padding: 50px 0;
        }
    }
    .modal-timeline-container {
        .ant-modal-body {
            height: 600px;
        }
    }
    .timeline-thumbnail {
        width: 60px !important;
        margin-right: 10px;
    }

    .modal-timeline {
        .timeline::after {
            left: 10px;
        }
        .timeline {
            .timeline-start {
                left: 10px;
            }
        }
        .container.left::after,
        .container.right::after {
            left: 5px;
        }
        .container.left::before,
        .container.right::before {
            left: 15px;
        }
        .container.left,
        .container.right {
            padding: 25px 0px 25px 60px;
        }
        .timeline-thumbnail {
            width: 50px !important;
        }
        .container.left .date,
        .container.right .date {
            left: 70px;
        }
        .container {
            &.right,
            &.left {
                .dot {
                    top: calc(50% + -5px);
                    left: 5px;
                }
            }
        }
    }
    .container {
        &.right,
        &.left {
            .dot {
                top: calc(50% + -5px);
                left: 35px;
            }
        }
    }
    
}
@media (max-width: 576px) {
    .modal-timeline-container {
        .ant-modal-body {
            height: 500px;
        }
    }
}
@media (max-width: 414px) {
    .modal-timeline-container {
        .ant-modal-body {
            height: 400px;
        }
    }
}
.timeline-thumbnail {
    width: 64px;
    margin-top: 10px;
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;