
                        @import "src/assets/less/variables.less";
                    
.christmas-wrapper {
    padding: 120px 0 500px;
    // background-color: #0C0A5A;
    background: url(../../../assets/images/christmas/bg1.jpg) no-repeat;
    background-size: cover;

    .section-shape {
        z-index: 0;
    }
    .section-shape-beast-1 {
        top: 12%;
        left: 5%;

        img {
            scale: 0.7;
        }
    }
    .section-shape-beauty-1 {
        top: 15%;
        right: 4%;
        left: unset;

        img {
            scale: 0.8;
            transform: rotate(0);
        }
    }
    .section-shape-beauty-2 {
        top: 32%;
        right: 7%;

        img {
            scale: 0.7;
            transform: rotate(0);
        }
    }
    .section-shape-mfg-1 {
        top: 27%;
        left: 12%;
    }
    .section-shape-mfg-2 {
        top: 11%;
        right: 17%;
    }
    .section-shape-mfr-1 {
        top: 35%;
        left: 8%;

        img {
            scale: 1.3;
            transform: rotate(0);
        }
    }
    .section-shape-mfr-2 {
        top: 43%;
        right: 5%;
    }
}
.footer-christmas {
    background-color: #0c0a5a;
    text-align: center;
    padding-bottom: 80px;
}
@media (max-width: 1200px) {
    .christmas-wrapper {
        padding: 120px 0 400px;
    }
}
@media (max-width: 1140px) {
    .christmas-wrapper {
        .section-shape-beast-1,
        .section-shape-beauty-1 {
            display: none;
        }
    }
}
@media (max-width: 996px) {
    .christmas-wrapper {
        padding: 110px 0 300px;
    }
}
@media (max-width: 768px) {
    .christmas-wrapper {
        padding: 100px 0 150px;
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;