
                        @import "src/assets/less/variables.less";
                    
.countdown-item {
    position: relative;
    .countdown {
        background: #2a1143;
        border-radius: 7px;
        // width: 56px;
        // height: 40px;
        position: relative;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        .countdown-number {
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            // span {
            font-family: "Race Sport", sans-serif;
            color: #f0489f;
            font-size: 28px;
            line-height: 40px;
            width: 56px;
            height: 40px;
            text-align: center;
            // }
        }
    }
    .countdown-title {
        font-size: 14px;
        text-align: center;
    }
    &:not(:last-child):after {
        content: ":";
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(-50%, -50%);
        color: #f0489f;
        font-family: "Race Sport";
    }
    &.valentine-event {
        .countdown {
            width: 44px;
            height: 32px;
            margin: 0 8px;
            background: rgba(255, 255, 255, 0.5);
            .countdown-number {
                span {
                    font-size: 20px;
                    line-height: 34px;
                    color: #f53770;
                }
            }
        }
        .countdown-title {
            color: #452863;
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media (max-width: 768px) {
    .countdown-item {
        .countdown {
            width: 46px;
            &:first-child {
                margin-left: 0;
            }
        }
        &:not(:last-child):after {
            margin-left: 10px;
        }
    }
}

@media (max-width: 576px) {
    .countdown-item {
        .countdown {
            width: 30px;
            height: 30px;
            margin: 0 5px;
            .countdown-number {
                span {
                    font-size: 16px;
                    line-height: 36px;
                }
            }
            &:first-child {
                margin-left: 0;
            }
        }
        &:not(:last-child):after {
            margin-top: 3px;
            margin-left: 15px;
        }
    }
    .countdown-item {
        &.valentine-event {
            .countdown {
                width: 30px;
                height: 30px;
                margin: 0 5px;
                background: rgba(255, 255, 255, 0.5);
                .countdown-number {
                    span {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;