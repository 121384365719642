
                        @import "src/assets/less/variables.less";
                    
.battle-team {
    padding-bottom: 40px;
    position: relative;
    background-image: url(../../../../assets/images/clan-battle/moon-bg.png);
    background-repeat: no-repeat;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(../../../../assets/images/clan-battle/bg.png);
        background-repeat: no-repeat;
        // background-size: cover;
        z-index: -1;
    }
    .clan-battle-logo {
        img {
            z-index: 2;
        }
    }
    .clan-battle-title {
        margin-top: -80px;
        h1 {
            z-index: 2;
            font-size: 48px;
            margin-bottom: 10px;
            line-height: 60px;
            background-image: linear-gradient(to right top, #ff84dd, #ffffff, #00f0ff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            filter: drop-shadow(-2px 3px #1749ff);
        }
    }
    .clan-battle-status {
        h3 {
            margin-bottom: 20px;
            z-index: 5;
            font-size: 28px;
        }
    }
    .clan-battle-team {
        position: relative;
        .battle-team-image {
            img {
                &.left-team-image,
                &.right-team-image {
                    max-width: 600px;
                }
                &.left-team-image {
                    margin-right: 20px;
                }
                &.right-team-image {
                    margin-left: 40px;
                }
            }
            .left-statistic {
                position: absolute;
                bottom: -105px;
                right: 120px;
                .statistic {
                    width: 500px;
                }
            }
            .right-statistic {
                position: absolute;
                bottom: -105px;
                left: 20px;
            }
            .statistic {
                margin-top: 20px;
                display: flex;
                justify-content: end;
                gap: 50px;
                span {
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 28px;
                }
                h4 {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 34px;
                    width: 80px;
                }
                .total-level,
                .time-spent {
                    display: flex;
                    margin: 0 25px;
                    .total-level-statistic,
                    .time-spent-stastictic {
                        margin-left: 15px;
                    }
                }
                // .total-level-icon,
                // .time-spent-icon {
                //     padding-top: 5px;
                // }
            }
        }
        .battle-vs-image {
            z-index: 5;
            top: -30%;
            // left: 37%;
            position: absolute;
        }
    }
}
@media (max-width: 1600px) {
    .clan-battle-team {
        .battle-vs-image {
            left: 40%;
        }
    }
}
@media (max-width: 1200px) {
    .battle-team {
        .clan-battle-team {
            .battle-team-image {
                &.left,
                &.right {
                    margin: 0;
                }
                .left-team-image,
                .right-team-image {
                    width: 450px;
                }
                img {
                    &.left-team-image,
                    &.right-team-image {
                        margin: 0;
                    }
                }
                .left-statistic {
                    right: 55px;
                    .statistic {
                        width: 430px;
                    }
                }
                .right-statistic {
                    left: -20px;
                }
                .statistic {
                    gap: 0;
                }
            }

            .battle-vs-image {
                top: -30%;
                left: 38%;
                img {
                    width: 250px;
                }
            }
        }
    }
}
@media (max-width: 996px) {
    .battle-team {
        .clan-battle-team {
            .battle-team-image {
                .left-team-image,
                .right-team-image {
                    width: 320px;
                }
                .left-statistic {
                    right: 0;
                }
                .left-statistic,
                .right-statistic {
                    margin-left: 0;
                    bottom: -80px;
                    .ant-row {
                        img {
                            margin-right: 10px;
                        }
                    }
                    img {
                        width: 40px;
                        margin: auto 0;
                    }
                    span {
                        font-size: 12px;
                        line-height: 15px;
                    }
                    h4 {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
                .left-statistic {
                    right: 10px;
                    .statistic {
                        width: 380px;
                    }
                }
            }
            .battle-vs-image {
                img {
                    width: 200px;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .clan-battle-logo {
        img {
            // width: 400px;
            width: 100%;
        }
    }
    .battle-team {
        background-image: none;
        .clan-battle-title {
            margin-top: -40px;
            h1 {
                font-size: 28px;
                line-height: 32px;
            }
        }
        .clan-battle-status {
            h3 {
                font-size: 22px;
            }
        }
        .clan-battle-team {
            .battle-team-image {
                .left-team-image,
                .right-team-image {
                    width: 260px;
                }
                .left-statistic {
                    right: -100px;
                }
                .left-statistic,
                .right-statistic {
                    bottom: -130px;
                    img {
                        width: 40px;
                        margin: auto 0;
                    }
                    span {
                        font-size: 12px;
                        line-height: 15px;
                    }
                    h4 {
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
                .statistic {
                    display: block;
                    .total-level-icon,
                    .time-spent-icon {
                        padding-top: 10px;
                    }
                }
            }
            .battle-vs-image {
                left: 40%;
                img {
                    width: 150px;
                }
            }
        }
    }
}
@media (max-width: 576px) {
    .battle-team {
        .clan-battle-team {
            .battle-team-image {
                .left-team-image,
                .right-team-image {
                    width: 180px;
                }
                .left-statistic {
                    right: -180px;
                }
            }
            .battle-vs-image {
                top: -48%;
                left: 33%;
                img {
                    width: 130px;
                }
            }
        }
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;