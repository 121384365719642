
                        @import "src/assets/less/variables.less";
                    
.event {
    background: @mf-bg-2;
    border-radius: @mf-border-radius-base;
    color: @mf-text-color;
    transition: all 0.5s;

    &:hover {
        box-shadow: 0 4px 100px rgba(228, 0, 123, 0.5);
        transform: translateY(-10px) !important;
    }

    .e(images, {
        position: relative;
        border-radius: @mf-border-radius-base;
        overflow: hidden;
    });

    .e(image, {
       object-fit: cover;
    });

    .e(status, {
        position: absolute;
        right: 12px;
        top: 12px;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
        height: 24px;
        padding: 2px 10px;
        color: @mf-bg-1;
        border-radius: 5px;

        &.upcoming {
            background-color: #A5D990;
        }

        &.live {
            background-color: @mf-primary-color;
        }

        &.expired {
            background-color: #EF2763;
            color: #FFFFFF;
        }
    });

    .e(eligible, {
        position: absolute;
        top: -7px;
        left: -30px;
        background: #5d5fef;
        width: 93px;
        height: 47px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        transform: rotate(-45deg);
    });

    .e(content, {
        padding: 20px 20px 13px 20px;
    });

    .e(title, {
        margin-bottom: 0;
    });

    .e(description, {
        margin-bottom: 13px;
    });

    .e(timeIcon, {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 8px;

        .m(start, {
            background-color: #452863;
            color: @mf-secondary-color;
        });

        .m(end, {
            background-color: #F0489F33;
            color: #F0489F;
        })
    });

    @media (max-width: 1200px) {
        .e(timeIcon, {
            width: 30px;
            height: 30px;
            margin-right: 6px;
        });
    }
    .e(eventTime, {
        margin: auto 0;
    });
    .e(timeTitle, {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 19px;
        .m(weeklyTitle, {
            color: #FFFFFF;
        });
    });

    .e(timeDate, {
        font-size: 16px;
    });

    .e(bottom, {
        padding: 10px 20px 10px 0;
        background-color: #452863;
        border-radius: 0 0 @mf-border-radius-base @mf-border-radius-base;
    });

    .e(ribbon, {
        clip-path: polygon(100% 0%, calc(100% - 0.75rem) 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        padding: 0 20px 0 15px;
        height: 28px;

        .m(holders, {
            background-color: #F0489F;
        });

        .m(everyone, {
            background-color: #EFAA5D;
        });

        .m(whitelisted, {
            background-color: #A16BD8;
        })
    });

    .e(count, {
        text-align: right;
    });

    .e(countTotal, {
        color: #fff;
        margin-right: 4px;
    });
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;