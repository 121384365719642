/*------ Darker Grotesque -------*/
@font-face {
    font-family: "Darker Grotesque";
    src: url(../fonts/darker-grotesque/DarkerGrotesque-SemiBold.ttf) format("truetype");
    font-style: normal;
    font-weight: 600;
}
@font-face {
    font-family: "Darker Grotesque";
    src: url(../fonts/darker-grotesque/DarkerGrotesque-Bold.ttf) format("truetype");
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: "Darker Grotesque";
    src: url(../fonts/darker-grotesque/DarkerGrotesque-ExtraBold.ttf) format("truetype");
    font-style: normal;
    font-weight: 800;
}

/*------ Race Sport -------*/
@font-face {
    font-family: "Race Sport";
    src: url(../fonts/race-sport/RaceSport.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
}

/*------ Ultra -------*/
@font-face {
    font-family: "Ultra Regular";
    src: url(../fonts/ultra/Ultra-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
}

/*------ Praise -------*/
@font-face {
    font-family: "Praise";
    src: url(../fonts/praise/Praise-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
}

/*------ Unbounded -------*/
@font-face {
    font-family: "Unbounded";
    src: url(../fonts/unbounded/Unbounded-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
}

/*------ Poppins -------*/
@font-face {
    font-family: "Poppins";
    src: url(../fonts/poppin/Poppins-SemiBold.ttf) format("truetype");
    font-style: normal;
    font-weight: 600;
}
