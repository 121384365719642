
                        @import "src/assets/less/variables.less";
                    
.yuliverse-event-tasks-modal {
    box-shadow: 0px 4px 44px rgba(107, 209, 216, 0.5);
    border-radius: 20px;
    .ant-modal-content {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 500px;
        border-radius: inherit;
        .ant-modal-close {
            top: 10px !important;
            right: 0 !important;
            left: unset !important;
        }
        .ant-modal-body {
            .task-challenge {
                h3 {
                    font-size: 32px;
                    line-height: 32px;
                    font-weight: 700;
                    text-align: center;
                    font-family: "Darker Grotesque", sans-serif;
                    margin-bottom: 28px;
                    text-transform: initial;
                }
                .list-task {
                    padding: 0;
                    .task {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 12px 10px;
                        margin: 10px 0;
                        border-radius: 7px;
                        background-color: #ffffff;
                        .task-detail {
                            color: #13324f;
                            display: flex;
                            margin-right: 10px;
                            // &.zealy {
                            //     align-items: start;
                            // }
                            .task-description {
                                margin-left: 10px;
                                p {
                                    font-size: 16px;
                                    font-weight: 700;
                                    line-height: 24px;
                                    margin-bottom: 0;
                                    text-transform: uppercase;
                                }
                                span,
                                a {
                                    color: #13324f;
                                    font-size: 20px;
                                    font-weight: 700;
                                    line-height: 24px;
                                }
                                a {
                                    text-decoration: underline;
                                }
                            }
                        }
                        .task-claim {
                            button {
                                width: 100%;
                                height: 32px;
                                line-height: 30px;
                                &:disabled {
                                    color: #020722;
                                    background-color: #90829d;
                                }
                            }
                        }
                    }
                }
            }
        }
        .task-date {
            top: 10px;
            right: 22px;
            position: absolute;
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;
            margin: 2px;
            font-family: "Ultra Regular", sans-serif;
            text-shadow: 4px 1px 2px rgba(0, 0, 0, 0.3);
        }
    }
}

@media (max-width: 768px) {
    .yuliverse-event-tasks-modal {
        .ant-modal-content {
            .ant-modal-body {
                .task-challenge {
                    h3 {
                        font-size: 28px;
                        line-height: 28px;
                        font-weight: 700;
                        margin-bottom: 22px;
                    }
                    .list-task {
                        .task {
                            .task-detail {
                                img {
                                    width: 40px;
                                }
                                .task-description {
                                    p {
                                        font-size: 14px;
                                        font-weight: 700;
                                        line-height: 16px;
                                    }
                                    span {
                                        font-size: 16px;
                                        font-weight: 700;
                                        line-height: 18px;
                                    }
                                }
                            }
                            .task-claim {
                                button {
                                    height: 30px;
                                    line-height: 28px;
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
            .task-date {
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;