
                        @import "src/assets/less/variables.less";
                    
.checkout-form {
    .e(group, {
        margin-bottom: 18px;
    });

    .e(title, {
        font-size: 36px;
        line-height: 41px;
        text-align: center;
        margin-bottom: 33px;
    });

    .e(heading, {
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 18px;
        font-weight: 700;
    });

    .e(input, {
        height: 48px;
        // margin-bottom: 12px;
        border: 1px solid transparent;
    });
    .ant-form-item-control {
        margin-bottom: 12px;
    }
    .ant-form-item-explain-error {
        &::before {
            content: "!";
            color: white;
            text-align: center;
            border: 1px solid #ff4d4f;
            background: #ff4d4f;
            border-radius: 50%;
            padding: 0px 7px 0px 6px;
            font-size: 14px;
            margin-right: 5px;
        }
    }
}
@media (max-width: 768px) {
    .checkout-form {
        .e(title, {
            font-size: 22px;
            margin-bottom: 18px;
        });
        .e(heading, {
            font-size: 16px;
        });
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;