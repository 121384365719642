
                        @import "src/assets/less/variables.less";
                    
.footer {
    background: url(../../assets/images/footer-bg.png) no-repeat center;
    background-size: cover;
    padding: 60px 24px 40px;
    text-align: center;

    .e(title, {
        font-size: 22px;
        line-height: 32px;
        margin-bottom: 20px;
    });

    .e(cta, {
        margin-bottom: 40px;
    });

    &__ctaButton {
        padding: 0 57px;
    }

    .e(socials, {
        margin-bottom: 40px;
    });

    .e(social, {
        background-color: @mf-primary-color;
        color: @mf-bg-1;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .3s;
        width: 45px;
        height: 45px;
        border-radius: 10px;
        font-size: 18px;

        &:hover {
            color: @mf-bg-1;
            box-shadow: 0 0 0 .2rem @mf-primary-color;
        }
    });

    .e(contact, {
        margin-bottom: 30px;
        color: #fff;
        font-size: 16px;
    });

    &__contactLink {
        margin-left: 12px;
        position: relative;
        top: -2px;
    }

    .e(copyright, {
        font-size: 16px;
    });

    .e(social-icon, {
       width: 1em;
    });

    @media(min-width: @mf-screen-sm-min) {
        padding-top: 120px;
        padding-bottom: 60px;

        .e(title, {
            font-size: 48px;
            line-height: 60px;
            margin-bottom: 38px;
        });

        .e(social, {
            width: 60px;
            height: 60px;
            border-radius: 23px;
            font-size: 24px;
        });

        .e(cta, {
            margin-bottom: 97px;
        });

        .e(socials, {
            margin-bottom: 76px;
        });

        .e(contact, {
            font-size: 18px;
        })
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;