h1, h2, h3, h4 {
    font-family: "Race Sport", sans-serif;
    text-transform: uppercase;
}

h1, .h1 {
    font-size: 48px;
    line-height: 60px;
}

h2, .h2 {
    font-size: 36px;
    line-height: 42px;
}

h3, .h3 {
    font-size: 24px;
    line-height: 34px;
}

h4, .h4 {
    font-size: 16px;
    line-height: 26px;
}

.ant-btn {
    text-transform: uppercase;

    svg {
        vertical-align: text-bottom;
        margin-right: 8px;
    }

    &-primary {
        &.-primary-2 {
            background: @mf-primary-color-2;
            border-color: @mf-primary-color-2;
            color: #fff;
        }

        &:hover, &:focus {
            color: @mf-bg-1;
            background: @mf-primary-color;
            border-color: @mf-primary-color;
            box-shadow: 0 0 0 .2rem @mf-primary-color;
        }
    }
}


