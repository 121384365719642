
                        @import "src/assets/less/variables.less";
                    
.modal-reward-token-container,
.modal-reward-item-container {
    &.christmas-challenge-claim {
        padding: 0;
        height: fit-content;
        overflow: visible;
        // top:100px;
        .ant-modal-content {
            width: 100%;
            height: 100%;
            padding: 0 !important;
            border: solid 1px transparent;
            position: relative;
            background-color: none !important;
            border-radius: 15px;
            margin: auto;
            box-shadow: 0 0 20px 0 rgba(161, 107, 216, 0.7);
            .ant-modal-body {
                padding: 0;
            }
        }
        .claim-reward {
            .claim-title {
                position: relative;
                margin-top: 35px;
                img {
                    width: 100%;
                    padding: 50px 50px 0;
                }
                p {
                    display:block;
                    color: #551f00;
                    width: 100%;
                    height: fit-content;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 26.866px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 38.807px;
                    padding: 0 60px;
                    margin: 0;
                }
            }
            .claim-bg {
                width: 570px;
                height: 100%;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0;
                left: 0;
                z-index: 0;
                overflow: hidden;
                img {
                    object-fit: cover;
                }
            }
            h2 {
                text-transform: none;
                color: #4dfff6;
                font-size: 96px;
                line-height: 108px;
                font-family: "Praise", sans-serif;
            }
            .claim-image {
                position: relative;
                display: block;
                width: 408.828px;
                height: 408.828px;
                padding: 0;
                margin: auto;
                margin-top: 33px;
            }
            .claim-text {
                position: relative;
                text-align: center;
                height: 22px;
                color: #fff;
                text-align: center;
                font-family: Race Sport;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 42px;
                margin-bottom: 0;
                margin-top: 53.46px;
            }
        }
        .claim-reward {
            .button-container {
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                gap: 13px;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                padding: 0 25px;
                margin-bottom: 27px;
                .button-secondary {
                    width: 100%;
                    color: #ffffff;
                    border: #e4007b;
                    background-color: #e4007b;
                    border-radius: 10.5px;
                    height: 72px;

                    &.green {
                        background: #4ccbc9;
                        border: #4ccbc9;
                    }
                    span {
                        color: #fff;
                        height: 100%;
                        text-align: center;
                        font-family: Darker Grotesque;
                        font-size: 27px;
                        font-style: normal;
                        font-weight: 800;
                        line-height: 72px;
                        text-transform: none !important;
                    }
                }
            }
        }
    }
}
.modal-reward-token-container {
    &.christmas-challenge-claim {
        .ant-modal-content {
            &::before {
                border-radius: 18px;
                top: -3px !important;
                right: -3px !important;
                bottom: -3px !important;
                left: -3px !important;
                background: linear-gradient(to right, #61aebe, #bc3b99);
                box-shadow: 0px 5.97px 74.629px 0px rgba(161, 107, 216, 0.50);
            }
        }
    }
}
.modal-reward-item-container {
    &.christmas-challenge-claim {
        .ant-modal-content {
            &::before {
                border-radius: 20px;

                top: -4px !important;
                right: -1px !important;
                bottom: 3px !important;
                left: -1px !important;
                background: linear-gradient(to right, #61aebe, #bc3b99);
                box-shadow: 0px 5.97px 74.629px 0px rgba(161, 107, 216, 0.50);
            }
        }
    }
}
@media (max-width: 768px) {
    .modal-reward-token-container,
    .modal-reward-item-container {
        &.christmas-challenge-claim {
            .claim-reward {
                h1 {
                    padding: 50px;
                    font-size: 96px;
                    line-height: 60px;
                }
                h2 {
                    font-size: 68px;
                    line-height: 54px;
                }
                h3 {
                    font-size: 32px;
                }
                img {
                    padding: 0;
                }
            }
            .ant-modal-content {
                &::before {
                    top: -1px !important;
                    right: -3px !important;
                    bottom: 1px !important;
                    left: -3px !important;
                    margin: -2px !important;
                }
            }
        }
    }
}
@media (max-width: 576px) {
    .modal-reward-token-container,
    .modal-reward-item-container {
        &.christmas-challenge {
            .claim-reward {
                .claim-title {
                    img {
                        padding: 0;
                    }
                }
            }
        }
    }
}
.modal-reward-token-container {
    &.christmas-challenge-claim {
        .ant-modal-content {
            // background-image: none !important;
            background: url(../../../assets/images/lunar/claim-bg.png) no-repeat center !important;
            //    overflow: visible;
            background-size: auto !important;
        }
    }
}

.modal-reward-item-container {
    &.christmas-challenge-claim {
        .ant-modal-content {
            background: url(../../../assets/images/lunar/claim-bg.png) no-repeat center !important;
            background-size: auto !important;
            // background-position: center;
            // overflow: visible;
            // background-image: none !important;
        }
    }
}

.text-main {
    color: #020722;
}

.z-index-2000 {
    z-index: 2000;
}

@media (max-width: 1076px) {
    .modal-reward-token-container,
    .modal-reward-item-container {
        width: 420px !important;
        height: fit-content;
        &.christmas-challenge-claim {
            .ant-modal-content {
                width: 420px;
                height: fit-content;
                &::before {
                    border-radius: 18px;

                    top: -3px !important;
                    right: -3px !important;
                    bottom: -3px !important;
                    left: -3px !important;
                    background: linear-gradient(to right, #61aebe, #bc3b99);
                }
            }
            .claim-reward {
                .claim-title {
                    position: relative;
                    margin-top: 40px;

                    img {
                        width: 100%;
                        padding: 50px 50px 0;
                    }
                    p {
                        padding: 0 40px;
             
                        line-height: 33px;
                        font-size: 22px;
                    }
                }
                .claim-bg {
                    width: 500px;
                    // height: 465px;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    left: 0;
                    z-index: 0;
                }
                .claim-image {
                    margin-top: 30px;
                    width: 350px;
                    height: 350px;
                }
                p {
                    font-size: 28px;
                }
            }
            .claim-reward {
                .button-container {
                    margin-bottom: 20px;
                    .button-secondary {
                        height: 60px;

                        span {
                            line-height: 60px;
                            font-size: 25px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .modal-reward-token-container,
    .modal-reward-item-container {
        width: 400px !important;
        height: fit-content;
        &.christmas-challenge-claim {
            .ant-modal-content {
                width: 400px;
                height: fit-content;
                &::before {
                    border-radius: 18px;

                    top: -3px !important;
                    right: -3px !important;
                    bottom: -3px !important;
                    left: -3px !important;
                    background: linear-gradient(to right, #61aebe, #bc3b99);
                }
            }
            .claim-reward {
                .claim-title {
                    position: relative;
                    margin-top: 20px;
                    img {
                        width: 100%;
                        padding: 50px 50px 0;
                    }
                    p {
                        padding: 0 30px;
             
                        line-height: 28px;
                        font-size: 20px;
                    }
                }
                .claim-bg {
                    width: 450px;
                    height: 480px;
                    img {
                        width: 100%;
                        height: 100%;

                        object-fit: fill;
                    }
                }
                .claim-image {
                    margin-top: 20px;
                    width: 300px;
                    height: 300px;
                }
                p {
                    font-size: 28px;
                }
            }
            .claim-reward {
                .button-container {
                    .button-secondary {
                        height: 50px;
                        span {
                            line-height: 50px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .modal-reward-token-container,
    .modal-reward-item-container {
        width: 350px !important;
        height: fit-content;
        top:50px !important;
      margin-bottom: 30px;
        &.christmas-challenge-claim {
            .ant-modal-content {
                width: 350px;
                height: fit-content;
                &::before {
                    border-radius: 18px;

                    top: -3px !important;
                    right: -3px !important;
                    bottom: -3px !important;
                    left: -3px !important;
                    background: linear-gradient(to right, #61aebe, #bc3b99);
                }
            }
            .claim-reward {
                .claim-title {
                    position: relative;
                    margin-top: 20px;
                    img {
                        width: 100%;
                        padding: 50px 50px 0;
                    }
                    p {
                        padding: 0 20px;
                        font-size: 18px;
                    }
                }
                .claim-bg {
                    width: 350px;
                    height: 380px;
                    img {
                        width: 100%;
                        height: 100%;

                        object-fit: fill;
                    }
                }
                .claim-image {
                    margin-top: 15px;
                    width: 250px;
                    height: 250px;
                }
                p {
                    height: 40px;
                    line-height: 40px;
                    font-size: 22px;
                }
            }
            .claim-reward {
                .button-container {
                    gap: 10px;
                    padding: 0 20px;
                    .button-secondary {
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 380px) {
    .modal-reward-token-container,
    .modal-reward-item-container {
        width: 300px !important;
        height: fit-content;
        &.christmas-challenge-claim {
            .ant-modal-content {
                width: 300px;
                height: fit-content;
                &::before {
                    border-radius: 20px;

                    top: -3px !important;
                    right: -3.5px !important;
                    bottom: -3px !important;
                    left: -3.5px !important;
                    background: linear-gradient(to right, #61aebe, #bc3b99);
                }
            }
            .claim-reward {
                .claim-title {
                    position: relative;
                    margin-top: 20px;
                    img {
                        width: 100%;
                        padding: 50px 50px 0;
                    }
                    p {
                        padding: 0 20px;
                        font-size: 18px;
                    }
                }
                .claim-bg {
                    width: 350px;
                    height: 380px;
                    img {
                        width: 100%;
                        height: 100%;

                        object-fit: fill;
                    }
                }
                .claim-image {
                    margin-top: 15px;
                    width: 250px;
                    height: 250px;
                }
                p {
                    height: 40px;
                    line-height: 40px;
                    font-size: 22px;
                }
            }
            .claim-reward {
                .button-container {
                    gap: 10px;
                    padding: 0 20px;
                    .button-secondary {
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}


@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;