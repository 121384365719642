
                        @import "src/assets/less/variables.less";
                    
@root-selector: banner;

.@{root-selector} {
    position: relative;
    text-align: center;

    // &:not(.is-user-assets):not(.is-user-no-assets) {
    //     height: 100vh;
    //     padding-bottom: 38px;
    // }

    .e(container, {
        height: 100%;
        display: flex;
        flex-direction: column;
    });

    .e(image, {
       flex-grow: 1;
        margin: 0 -24px;

        .ant-image {
            height: 120%;

            &-img {
                height: 100%;
                object-fit: cover;
            }
        }
    });

    .e(title, {
        color: @mf-primary-color;
        font-size: 44px;
        line-height: 52px;
        margin-bottom: 11px;
        position: relative;
    });

    .e(sub-title, {
        font-weight: 700;
        color: #fff;
        font-size: 28px;
        line-height: 34px;
    });

    &__cta {
        margin-top: 46px;
    }

    &__ctaButton {
        padding: 0 45px;

        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &.is-user-assets {
        text-align: center;
        background-image: url("../../assets/images/banner/has-assets.banner.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 117px 0 53px;

        .banner {
            .e(title, {
                position: relative;
                top: unset;
                margin-bottom: 0;
            });
        }
    }

    &.is-user-no-assets {
        text-align: center;
        background-image: url("../../assets/images/banner/has-assets.banner.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 117px 0 53px;
    }

    // @media (max-width: 768px) {
    //     &.is-user-assets,
    //     &.is-user-no-assets {
    //         background-size: contain;
    //         padding: 117px 0 80px;
    //         background-image: url("../../assets/images/banner/mobile.banner.png");
    //     }
    // }

    @media (max-width: 576px) {
        &.is-user-assets,
        &.is-user-no-assets {
            background-size: auto, cover;
            padding: 117px 50px 80px;
            background-image: url("../../assets/images/banner/mobile.banner.png"),
                url("../../assets/images/banner/mobile.assets.banner.png");
            text-align: center;
        }
        &.is-not-logged-in {
            padding-top: 40px;
        }
    }

    &.is-user-no-assets {
        .@{root-selector} {
            .e(image, {
                .ant-image {
                    height: 140%;
                }
            });

            .e(title, {
                margin-bottom: 16px;
            });

            .e(sub-title, {
                font-size: 24px;
                line-height: 32px;
            });

            .e(cta, {
                margin-top: 20px;
            });

            .e(btn, {
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            });
        }
    }
    .bg {
        position: relative;
        .cup {
            position: absolute;
            top: 30%;
            right: 40%;
            transform: scale(1.2);
            img {
                animation: move-vertical 5s linear infinite;
            }
        }
    }
    @media (min-width: 1366px) {
        &.is-user-no-assets {
            background-size: auto;
        }
        .e(image, {
            top: 0;
            right: 5%;
        });
    }
    @media (max-width: 1366px) {
        .e(image, {
            top: 0;
            right: 2%;
        });
    }
    @media (min-width: @mf-screen-sm-min) {
        &:not(.is-user-assets) {
            height: auto;
            padding: 220px 0px 260px;
            text-align: initial;
        }
        &.is-user-no-assets {
            text-align: center;
        }
        &.is-not-logged-in {
            padding: 250px 0 80px;
        }

        .e(title, {
            font-size: 48px;
            line-height: 60px;
            margin-bottom: 5px;
        });

        .e(sub-title, {
            font-size: 32px;
            line-height: 40px;
        });

        .e(image, {
            position: absolute;
            top: 0;
            right: 10%;
        });

        .e(cta, {
            margin-top: 35px;
            align-self: flex-start;
        });

        &.is-user-no-assets {
            .@{root-selector} {
                .e(title, {
                    margin-bottom: 5px;
                });

                .e(cta, {
                    display: flex;
                    margin-top: 35px;
                });

                .e(btn, {
                    width: initial;

                    &:not(:last-child) {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }
                });
            }
        }

        &.is-user-assets {
            background-size: cover;
            padding: 280px 0 320px;
            background-image: url("../../assets/images/banner/has-assets.banner.png") !important;
            .@{root-selector} {
                .e(title, {
                    top: 0
                });
            }
        }
    }
    @media (max-width: 996px) {
        .e(image, {
            top: 0;
            right: 0px !important;
        });
        .bg {
            .cup {
                top: 30%;
                right: 42%;
                transform: scale(1.2);
            }
        }
    }
    @media (max-width: 768px) {
        .bg {
            .cup {
                top: 30%;
                right: 37%;
                transform: scale(1.2);
            }
        }
    }
    @media (max-width: 576px) {
        .bg {
            padding-bottom: 20px;
            .cup {
                top: 20%;
                right: 32%;
                transform: scale(1);
            }
        }
    }
    @media (max-width: 542px) {
        .bg {
            .cup {
                top: 22%;
                right: 30%;
                transform: scale(1);
            }
        }
    }
    @media (max-width: 480px) {
        .bg {
            .cup {
                top: 20%;
                right: 30%;
                transform: scale(1);
            }
        }
    }
    @media (max-width: 440px) {
        .bg {
            .cup {
                top: 20%;
                right: 27%;
                transform: scale(0.9);
            }
        }
    }
    @media (max-width: 410px) {
        .bg {
            .cup {
                top: 15%;
                right: 25%;
                transform: scale(0.8);
            }
        }
    }
    @media (max-width: 376px) {
        .bg {
            .cup {
                top: 15%;
                right: 22%;
                transform: scale(0.7);
            }
        }
    }
}

@keyframes move-vertical {
    0% {
        transform: translate(0);
    }
    25% {
        transform: translate(0, 10px);
    }
    50% {
        transform: translate(0, 5px);
    }
    75% {
        transform: translate(0, -5px);
    }
    to {
        transform: translate(0);
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;