
                        @import "src/assets/less/variables.less";
                    
.ntfs-card {
    text-align: center;
    transition: all 0.5s;

    &:hover {
        transform: translateY(-10px);
    }

    .e(inner, {
        position: relative;
        background-color: @mf-bg-2;
        border-radius: @mf-border-radius-base;
        border: 2px solid transparent;
        background-clip: padding-box;
        padding: 20px;
        transition: all 0.5s;

        &:hover {
            box-shadow: 0 4px 100px rgba(228, 0, 123, 0.5);

            &::before {
                content: "";
            }
        }

        &::before {
            content: none;
            position: absolute;
            inset: 0;
            z-index: -1;
            margin: -2px;
            border-radius: inherit;
            background-image: linear-gradient(to right bottom, #4cbfcb, #E4007B);
        }
    });

    .e(top, {
        display: flex;
        justify-content: right;
        padding: 10px;
    });

    .e(label, {
        display: inline-block;
        line-height: 20px;
        height: 24px;
        padding: 0 8px;
        border-radius: 3px;
        font-size: 16px;

        .m(rare, {
            color: #A5D990;
            background-color: fade(#A5D990, 20%);
        })
    });

    .e(title, {
        font-size: 20px;
        line-height: 28px;
        color: #fff;
        margin-top: 16px;
    });

    .e(image, {
        object-fit: contain;
    });
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;