
                        @import "src/assets/less/variables.less";
                    
.modal-reward-token-container,
.modal-reward-item-container {
    &.valentine-event {
        .ant-modal-content {
            padding: 0 !important;
        }
        .task-reward {
            h2 {
                text-transform: none;
                color: #4dfff6;
                font-size: 96px;
                line-height: 108px;
                font-family: "Praise", sans-serif;
            }
        }
    }
}
.modal-reward-token-container {
    &.valentine-event {
        .ant-modal-content {
            &::before {
                top: -1px !important;
                right: -3px !important;
                bottom: -1px !important;
                left: -3px !important;
                margin: -2px !important;
            }
        }
    }
}
.modal-reward-item-container {
    &.valentine-event {
        .ant-modal-content {
            &::before {
                top: -3px !important;
                right: -2px !important;
                bottom: -3px !important;
                left: -2px !important;
                margin: -2px !important;
            }
        }
    }
}
@media (max-width: 768px) {
    .modal-reward-token-container,
    .modal-reward-item-container {
        &.valentine-event {
            .task-reward {
                h1 {
                    padding: 50px;
                    font-size: 96px;
                    line-height: 60px;
                }
                h2 {
                    font-size: 68px;
                    line-height: 54px;
                }
                h3 {
                    font-size: 32px;
                }
                img {
                    padding: 0;
                }
            }
            .ant-modal-content {
                &::before {
                    top: -1px !important;
                    right: -3px !important;
                    bottom: -1px !important;
                    left: -3px !important;
                    margin: -2px !important;
                }
            }
        }
    }
}

.modal-reward-token-container {
    &.valentine-event {
        .ant-modal-content {
            background: url(../../../assets/images/valentine/reward-bg.png) no-repeat center !important;
            background-size: cover !important;
        }
    }
}

.modal-reward-item-container {
    &.valentine-event {
        .ant-modal-content {
            background: url(../../../assets/images/valentine/reward-bg-2.jpg) no-repeat center !important;
            background-size: cover !important;
        }
    }
}

.text-main {
    color: #020722;
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;