
                        @import "src/assets/less/variables.less";
                    
.header {
    &.christmas-challenge {
        &.is-top {
            .inner {
                a {
                    color: #FFF;
                    &:hover {
                        color: #e4007b;
                    }
                }
            }
        }
    }
}

.calendar-christmas-challenge-container {
    display: block;
    margin: 0 auto;
    padding: 0 10px;
    // max-width: 1370px;
    .christmas-challenge-header {
        text-align: center;
        margin-bottom: 58.42px;
        display:flex;
        flex-direction: column;
        align-items: center;
        gap:5px;
     img{
        &:nth-child(1){
            width: 765px;
            height: 295px;
        }
        &:nth-child(2){
            width: 1044px;
            height: 210px;
        }
     }
    }
    .title {
        text-align: center;
        img {
            width: 100%;
            max-width: 1000px;
        }
        .sub-title {
            display: flex;
            justify-content: center;
            align-items: center;
            h1 {
                font-family: "Praise", sans-serif;
                font-size: 96px;
                line-height: 108px;
                color: #4dfff6;
                text-transform: none;
            }
        }
    }

    .event-notification {
        h4 {
            color: #FFF;
            text-align: center;
            font-family: Race Sport;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
            margin-top: 58.42px;
        }
        .list-event-notification {
            width: 100%;
            display: flex;
            padding: 0 30px;
            margin-bottom: 40px;
            justify-content: center;
            gap:15px;
            .notify {
                z-index: 2;
                margin: 0 5px;
                padding: 7px 15px;
                border-radius: 75px;
                background-color: rgba(255, 255, 255, 0.3);
                color: #FFF;
                font-family: Darker Grotesque;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                p {
                    margin: 0;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 600;
                    font-family: "Darker Grotesque", sans-serif;
                    .index {
                        width: 24px;
                        height: 24px;
                        line-height: 22px;
                        padding-right: 1px;
                        display: inline-block;
                        margin-right: 10px;
                        background-color: #ffffff;
                        color:#2A284D;
                        border-radius: 50%;
                        position: relative;       
                        &:before {
                            content: "";
                            display: block;
                            position: absolute;
                            background: radial-gradient(
                                50% 50% at 50% 50%,
                                #39DCDC,
                                #53C1FF
                            );
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            animation: live 1.5s ease-in-out infinite alternate;
                            z-index: -1;
                        }
                    }
                }
            }
        }
        @keyframes live {
            0% {
                transform: scale(1, 1);
            }
            100% {
                transform: scale(1.5, 1.5);
                background-color: rgba(#e4007b, 0);
            }
        }
    }

    .calendar {
        max-width: 1170px;
        display: block;
        margin: 0 auto;
        // padding: 3px;
        border-radius: 20px;
        color: #ffffff;
        position: relative;
        border: 3px solid #FFF;
        background-clip: padding-box;
        // background-image: linear-gradient(180deg, #C0F3FF -1.96%, #FFFFFF 69.78%);
        box-shadow: 0px 4px 80px 0px #1A789C;
        // opacity: 0.5;
        .calendar-border {
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 18px;
            padding: 50px 70px;
            h4 {
                color: #ffffff;
            }
        }

        .month {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            font-weight: 300;
            margin-bottom: 20px;
            .text-summer {
                padding-top: 10px;
                color: #FFF;
                font-family: Race Sport;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px; /* 166.667% */
                text-transform: uppercase;
            }
            .task-countdown {
                display: flex;
                align-items: center;
                .count-text {
                    color: #FFF;
                    font-family: Darker Grotesque;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px; /* 114.286% */
                    text-transform: uppercase;
                    margin-right: 10px;
                }
                .summer-event {
                    .countdown {
                        margin: 0 5px;
                        background-color: transparent;
                        .countdown-number {
                            color: #00E1FF;
                        }
                    }
                    &:not(:last-child)::after{
                        color:#00E1FF
                    }
                }
            }
            .year {
                font-weight: 600;
                margin-left: 10px;
            }

            .nav {
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: #0a3d62;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                transition-duration: 0.2s;
                position: relative;

                &:hover {
                    background: #eee;
                }
            }
        }
        h3 {
            font-size: 28px;
        }
        .days {
            display: grid;
            justify-content: center;
            align-items: center;
            grid-template-columns: repeat(6, 1fr);
            font-size: 16px;
            line-height: 32px;
            margin-bottom: 20px;
            font-weight: 700;
            border-radius: 5px;
            grid-gap: 5px;

            span {
                width: 100%;
                color: #280748;
                background-color: #4ccbc9;
                justify-self: center;
                align-self: center;
                text-align: center;
                border-radius: 5px;
            }
        }
        .weekday {
            margin-bottom: 10px;
            div {
                color: #7c4f4c;
                font-weight: 800;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                text-transform: uppercase;
            }
        }
        .weekday,
        .dates {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-gap: 10px;
            .date-container {
                position: relative;
                .status-icon {
                    cursor: not-allowed;
                    position: absolute;
                    top: 0px;
                    left: 5px;
                    width: 32px;
                    height: 32px;
                    z-index: 12;
                }
                .task-icon {
                    pointer-events: none;
                    position: absolute;
                    bottom: 8px;
                    left: 14px;
                    width: 32px;
                    height: 32px;
                    z-index: 1;
                }
            }
            .today {
                border-radius: 7px;
                position: relative;
                border: 2px solid #ffffff;
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.6);
                transform: scale(1.15);
                z-index: 10;
                // &:before {
                //     content: "";
                //     position: absolute;
                //     top: 0;
                //     right: 0;
                //     bottom: 0;
                //     left: 0;
                //     // z-index: -1;
                //     margin: -@border;
                //     border-radius: inherit;
                //     background: linear-gradient(to right, #4ccbc9, #e4007b);
                // }
                .date {
                    &.not_eligible {
                        cursor: pointer;
                        filter: brightness(1) !important;
                    }
                }
            }

            .date {
                padding: 15px 0;
                cursor: pointer;
                position: relative;
                transition-duration: 0.2s;
                width: 100%;
                max-width: 160px;
                height: 128px;
                border-radius: 5px;
                margin: 0 auto;

                &.eligible {
                    background: url(../../assets/images/summer/claim-bg.png) no-repeat !important;
                    background-size: cover !important;
                }

                &.claimed,
                &.not_eligible {
                    background-color: #fff;
                    opacity: 0.5;
                    cursor: pointer;
                }

                &.upcoming {
                    &:hover {
                        opacity: 0.8;
                    }
                }

                .task-eligible {
                    text-align: center;
                    .task-eligible-img {
                        img {
                            width: 54px;
                            height: 48px;
                        }
                    }
                    .task-eligible-button {
                        button {
                            margin-top: 8px;
                            height: 32px;
                            width: 96px;
                            font-size: 18px;
                            line-height: 24px;
                            font-weight: 800;
                            z-index: 10;
                        }
                        &.loading-button {
                            button {
                                pointer-events: none;
                            }
                        }
                    }
                }

                .task-detail {
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    text-align: center;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 24px;
                    .rest {
                        p {
                            font-size: 32px;
                            // padding-top: 30px;
                        }
                    }
                    // .distance {
                    //     p {
                    //         padding-top: 25px;
                    //     }
                    // }
                    // .run {
                    //     p {
                    //         padding-top: 25px;
                    //     }
                    // }
                }

                img {
                    width: 100%;
                    object-fit: cover;
                }
                .date-of-month {
                    right: 10px;
                    top: 3px;
                    position: absolute;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    margin: 2px;
                    font-family: "Ultra Regular", sans-serif;
                    text-shadow: 4px 1px 2px rgba(0, 0, 0, 0.3);
                }
                p {
                    margin: 0;
                    padding: 2px 12px;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;
                }
                // &:first-child {
                //     grid-column: 3;
                // }
            }
        }
    }
}

@media (max-width: 1140px) {
    .calendar-christmas-challenge-container {
        .christmas-challenge-header {
            margin-bottom: 50px;
            .big-text {
                font-size: 56px;
                line-height: 75px;
            }
            .small-text {
                font-size: 36px;
                line-height: 55px;
            }
            img{
                max-width: 100%;
                &:nth-child(1){
                    width: 700px;
                    height: 260px;
                }
                &:nth-child(2){
                    width: 900px;
                    height: 180px;
                }
            }
        }
        .event-notification {
            h4 {
                font-size: 18px;
                line-height: 30px;
            }
            .list-event-notification {
                width: 100%;
                display: block;
                padding: 0;
                margin: 0 auto;
                .notify {
                    margin: 10px 0;
                    p {
                        font-size: 16px;
                        line-height: 20px;
                        text-align: left;
                    }
                    span {
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media (max-width: 996px) {
    .calendar-christmas-challenge-container {
        .christmas-challenge-header {
            margin-bottom: 45px;
            .big-text {
                font-size: 50px;
                line-height: 70px;
            }
            .small-text {
                font-size: 30px;
                line-height: 50px;
            }
            
        }
        .title {
            margin-bottom: 30px;
            h2 {
                font-size: 28px;
                margin-bottom: 10px;
            }
            .sub-title {
                h1 {
                    font-size: 40px;
                    line-height: 50px;
                }
                img {
                    margin-top: -50px;
                    &.left {
                        margin-right: 10px;
                    }
                    &.right {
                        margin-left: 10px;
                    }
                }
            }
        }
        .calendar {
            .month {
                display: block;
                .task-countdown {
                    gap: 10px;
                    padding: 0;
                    background-color: transparent;
                }
            }
            .weekday {
                display: none;
            }
            .dates {
                grid-template-columns: repeat(4, 1fr);
                .date {
                    .task-detail {
                        text-align: center;
                        .rest {
                            p {
                                font-size: 28px;
                            }
                        }
                        .distance {
                            p {
                                font-size: 18px;
                            }
                        }
                        .run {
                            p {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .calendar-christmas-challenge-container {
        .christmas-challenge-header {
            margin-bottom: 35px;
            .big-text {
                font-size: 40px;
                line-height: 60px;
            }
            .small-text {
                font-size: 20px;
                line-height: 40px;
            }
            img{
                &:nth-child(1){
                   
                    height: 220px;
                }
                &:nth-child(2){
                   
                    height: 160px;
                }
            }
        }
        .title {
            h2 {
                font-size: 24px;
                margin-bottom: 10px;
            }
            .sub-title {
                h1 {
                    font-size: 36px;
                    line-height: 40px;
                }
                img {
                    margin-top: -50px;
                    &.left {
                        margin-right: 10px;
                    }
                    &.right {
                        margin-left: 10px;
                    }
                }
            }
        }
        .calendar {
            h3 {
                font-size: 24px;
            }
            .calendar-border {
                padding: 40px;
            }
            .dates {
                grid-template-columns: repeat(3, 1fr);
            }
        }
        .ant-tooltip-open {
            display: none !important;
        }
    }
}

@media (max-width: 576px) {
    .calendar-christmas-challenge-container {
        .christmas-challenge-header {
            margin-bottom: 20px;
            .big-text {
                font-size: 30px;
                line-height: 50px;
            }
            .small-text {
                font-size: 14px;
                line-height: 30px;
            }
            img{
                &:nth-child(1){
                   
                    height: 180px;
                }
                &:nth-child(2){
                   
                    height: 120px;
                }
            }
        }
        .sub-title {
            img {
                display: none;
            }
        }
        .calendar {
            h3 {
                margin-bottom: 0;
                font-size: 16px;
            }
            .month {
                .task-countdown {
                    gap: 5px;
                    .count-text {
                        font-size: 12px;
                        margin-right: 5px;
                    }
                    .summer-event {
                        .countdown {
                            .countdown-number {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
            .calendar-border {
                padding: 20px;
            }
            .dates {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}


@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;