
                        @import "src/assets/less/variables.less";
                    
.event-header {
    margin-top: 150px;
    .header-banner {
        img {
            width: 100%;
            border-radius: 20px;
        }
    }
    .event-status {
        position: relative;
        .status {
            text-transform: uppercase;
            font-weight: 800;
            font-size: 18px;
            text-align: center;
            position: absolute;
            color: #020722;
            line-height: 26px;
            height: 28px;
            top: 20px;
            right: 20px;
        }
        .live {
            width: 56px;
        }
        .upcoming {
            width: 116px;
        }
        .expired {
            width: 85px;
            color: #ffffff;
        }
    }
    .header-tag {
        position: relative;
        padding: 40px;
        border-radius: 20px;
        box-shadow: 0px -20px 60px rgba(0, 0, 0, 0.5);
        background: linear-gradient(178.52deg, #03071f 2.3%, #351c4f 98.74%);
        .ant-row {
            margin-top: 20px;
        }
        .header-title {
            margin-top: auto;
            .title {
                padding-top: 20px;
                font-size: 36px;
                line-height: 32px;
                margin: auto 0;
            }
            @media (max-width: 768px) {
                .title {
                    padding-top: 0;
                    line-height: 22px;
                    font-size: 22px;
                }
            }
        }
        .event-tag {
            text-transform: uppercase;
            .triangle {
                font-size: 18px;
                position: absolute;
                top: -15px;
                left: -40px;
                height: 26px;
                &::after {
                    content: "";
                    height: 16px;
                    width: 16px;
                    position: absolute;
                    background-color: #03071f;
                    top: 12%;
                    right: -9px;
                    border-top: #0c0a27 solid 1px;
                    border-left: #0c0a27 solid 1px;
                    background: #0c0a27;
                    transform: rotate(-45deg);
                }
            }
            .holders,
            .everyone {
                width: 100px;
            }
            .whitelisted {
                width: 130px;
            }
        }
        .reward-claimed {
            .total-reward {
                font-size: 20px;
                color: #a8adc3;
                margin-right: 20px;
                .total-count {
                    color: #ffffff;
                    font-weight: 600;
                }
                .anticon-gift {
                    color: @mf-primary-color;
                }
            }
            .available-claim,
            .unavailable-claim {
                .anticon {
                    margin-right: 5px;
                }
            }
            .available-claim {
                color: @mf-primary-color;
            }
            .unavailable-claim {
                color: #ef2763;
            }
            .raffle-game-end {
                img {
                    margin-right: 5px;
                }
                color: #efaa5d;
            }
        }
        .event-time {
            margin-top: 0;
        }
        .time {
            .timeIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 44px;
                height: 44px;
                border-radius: 50%;
                margin-right: 8px;
                margin-top: 6px;
            }
            .weeklyTitle {
                margin: auto 0;
                color: #ffffff;
            }
            .start {
                background-color: #452863;
                color: @mf-secondary-color;
            }

            .end {
                background-color: #f0489f33;
                color: #f0489f;
            }
        }
        .btn-share-row {
            margin-top: auto;
        }
    }
    @media (max-width: 768px) {
        .header-title {
            margin-top: 20px !important;
        }
        .header-tag {
            padding: 30px 20px;
            .time {
                .timeTitle {
                    font-size: 14px;
                }
                .timeDate {
                    font-size: 16px;
                }
            }
        }
        .triangle {
            top: 0px !important;
            left: -20px !important;
        }
    }
    @media (max-width: 400px) {
        .header-title {
            margin-top: 40px !important;
        }
    }
    @media (max-width: 365px) {
        .header-tag {
            .time {
                .timeIcon {
                    width: 40px;
                    height: 40px;
                    margin-top: 8px;
                }
                .timeTitle {
                    font-size: 13px;
                }
                .timeDate {
                    font-size: 14px;
                }
            }
        }
    }
    @media (max-width: 335px) {
        .header-tag {
            .time {
                .timeIcon {
                    width: 32px;
                    height: 32px;
                    margin-top: 12px;
                }
                .timeTitle {
                    font-size: 12px;
                }
                .timeDate {
                    font-size: 13px;
                }
            }
        }
    }
}
@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;