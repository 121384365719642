
                        @import "src/assets/less/variables.less";
                    
.prize-pool-bg-wrapper {
    padding: 140px 0;
    // padding: 60px 0 100px;
    position: relative;
    &::before {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 1;
        position: absolute;
        background: url(../../../../assets/images/clan-battle/prize-pool-bg.png) no-repeat;
    }
}
.prize-pool-container {
    margin: auto;
    width: 70%;
    .prize-pool-instruction {
        margin-right: 40px;
        float: right;
        .instruction-section {
            margin-top: 35px;
        }
        .deposit-container {
            margin-top: 50px;
            .ant-table-wrapper {
                width: 100%;
                .ant-table-thead > tr > th,
                .ant-table-tbody > tr > td {
                    padding: 10px;
                }
                .ant-table-thead {
                    .ant-table-cell {
                        font-size: 14px !important;
                    }
                }
                .ant-table-tbody {
                    .ant-table-cell {
                        font-size: 20px !important;
                    }
                }
            }
            .unrelized {
                img {
                    width: 15px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
        .deposite-box {
            margin: 20px 0;
            .ant-avatar {
                margin-right: 10px;
            }
            padding: 20px;
            background-color: #020722;
            border: 2px solid transparent;
            background-clip: padding-box;
            border-radius: @mf-border-radius-base;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                inset: 0;
                z-index: -1;
                margin: -2px;
                border-radius: inherit;
                background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b);
            }
            button {
                width: 100%;
            }
        }
        button {
            border: 1px solid #4ccbc9;
            background-color: #4ccbc9;
            color: #020722;
            &:disabled {
                opacity: 0.3;
                cursor: not-allowed;
            }
        }
    }
    .prize-pool-box {
        padding: 30px 20px 20px;
        background-color: #020722;
        border: 2px solid transparent;
        background-clip: padding-box;
        border-radius: 20px;
        position: relative;
        h1 {
            margin: 0 0 10px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            background-image: linear-gradient(to right top, #ff84dd, #ffffff, #00f0ff);
            filter: drop-shadow(-2px 3px #1749ff);
            font-size: 56px;
            line-height: 56px;
        }
        &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: -1;
            margin: -2px;
            border-radius: inherit;
            box-shadow: 0 4px 50px rgb(161 107 216 / 50%);
            background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b);
        }
        .potential-win {
            font-size: 12px;
        }
        .time-to-predict {
            font-size: 14px;
        }
        .countdown-item {
            margin: 10px 0 30px;
            position: relative;
            .countdown {
                background: #2a1143;
                border-radius: 7px;
                width: 56px;
                height: 40px;
                text-align: center;
                margin: 0 10px;
                h3 {
                    color: #f0489f;
                    line-height: 42px;
                }
            }
            .countdonw-title {
                font-size: 14px;
                text-align: center;
            }
            &:not(:last-child):after {
                content: ":";
                position: absolute;
                top: 5px;
                right: -2px;
                color: #f0489f;
                font-family: "Race Sport";
            }
        }
        .amount-pick-container {
            display: flex;
            justify-content: center;
            position: relative;
            margin-bottom: 20px;
            .predict-amount {
                width: 100%;
                border: 1px solid #250e3a;
                border-radius: 5px;
                background: #250e3a;
                text-align: center;
                padding: 8px 2px;
                margin: 0 5px;
                cursor: pointer;
                &:first-child {
                    margin-right: 5px;
                }
                &:last-child {
                    margin-left: 5px;
                }
                h3 {
                    margin-bottom: 0;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
                h4 {
                    margin-bottom: 0;
                    font-size: 10px;
                    font-weight: 400;
                    color: #a8adc3;
                    line-height: 22px;
                }
                &.option-more {
                    h3 {
                        font-size: 12px;
                    }
                    h4 {
                        line-height: 26px;
                    }
                }
            }
            .selected {
                position: relative;
                border: 0px solid transparent;
                background-clip: padding-box;
                padding: 2px;
                background-image: linear-gradient(to right bottom, #4cbfcb, #e4007b);
                box-shadow: 0 4px 50px rgb(161 107 216 / 50%);
                .border-predict-amount {
                    width: 100%;
                    height: 100%;
                    background-color: #250e3a;
                    border-radius: 4px;
                    padding-top: 8px;
                    h4 {
                        color: #ffffff;
                    }
                }
            }
            .amount-more-container {
                position: absolute;
                display: flex;
                top: 70px;
                width: 100%;
                border: 1px solid #020722;
                background-color: #020722;
                padding: 5px;
                border-radius: 10px;
                z-index: 1;
                box-shadow: 0 4px 50px rgb(161 107 216 / 50%);
                .predict-amount {
                    h3 {
                        margin: 0;
                        font-size: 14px;
                    }
                    h4 {
                        margin: 0;
                        font-size: 10px;
                    }
                }
            }
        }
        .team-pick-container {
            margin: 0 5px 25px;
            border-radius: 5px;
            border: 1px solid #250e3a;
            background-color: #250e3a;
            padding: 20px;
            text-align: center;
            position: relative;
            &.winner-bg {
                background: url(../../../../assets/images/clan-battle/winner-bg.jpg) no-repeat;
                background-size: 100% 100%;
                // height: 320px;
            }
            .victory-team {
                .team {
                    width: 100%;
                    //     position: absolute;
                    //     left: 0;
                    //     right: 0;
                    //     bottom: 0;
                    //     top: 0;
                    //     margin: auto;
                    //     z-index: 2;
                    //     max-width: 250px;
                }
                .aura {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: -120px;
                    margin: auto;
                    z-index: 1;
                }
                .winner-bg {
                    width: 100%;
                }
            }
            .team-picker {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                cursor: pointer;
                .team1,
                .team2 {
                    filter: brightness(100%);
                    &.selected {
                        filter: brightness(100%);
                    }
                    &.un-selected {
                        filter: brightness(50%);
                    }
                    .aura1,
                    .aura2 {
                        z-index: -1;
                        top: -30px;
                        left: 80px;
                        position: absolute;
                    }
                }

                img {
                    max-width: 100%;
                }
            }
            .team-progress {
                width: 100%;
                height: 10px;
                display: flex;
                margin: 15px 0 5px;
                .team1 {
                    color: #b5216e;
                    background: #b5216e;
                    // width: 0;
                    // height: 0;
                    // border-bottom: 100px solid #b5216e;
                    // border-left: 100px solid transparent;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                    background-image: linear-gradient(to right, #b5216e, #c71d7b, #da1889, #ec0f98, #ff00a8);
                    margin-right: 3px;
                    box-shadow: 0 0 5px 0 rgb(236 69 156 / 90%);
                }
                .team2 {
                    color: #4ccbc9;
                    background: #4ccbc9;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    background-image: linear-gradient(to right, #4ccbc9, #45d8d5, #3ae5e2, #2af2ee, #05fffb);
                    box-shadow: 0 0 5px 0 rgb(76, 203, 201, 0.9);
                }
                .full-rate {
                    border-radius: 8px;
                    margin: 0;
                }
            }
        }
        .team-progress-number {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
        }
        .prize-pool-button {
            margin: 0 5px;
            button {
                width: 100%;
                border: 1px solid @mf-primary-color;
                background: @mf-primary-color;
                color: #020722;
                font-size: 18px;
                font-weight: 800;
            }
        }
    }
    .prize-warning {
        display: flex;
        padding-top: 10px;
        .prize-warning-item {
            border: 1px solid #21063c;
            background-color: #21063c;
            border-radius: 10px;
            padding: 15px 15px 20px;
            width: 33%;
            margin: 0 8px;
            p {
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
            }
            a {
                color: #a8adc3;
                text-decoration: underline;
            }
        }
    }
}
@media (max-width: 1200px) {
    .prize-pool-bg-wrapper {
        padding: 80px 0 40px;
    }
    .prize-pool-container {
        padding-top: 10px;
        width: 90%;
        .prize-pool-instruction {
            margin-right: 0;
            float: none;
            h2,
            h3 {
                margin: 0;
            }
        }
    }
}
@media (max-width: 768px) {
    .prize-pool-bg-wrapper {
        padding: 0 0 80px;
        // padding: 0 0 50px;
    }
    .prize-pool-container {
        .prize-pool-instruction {
            .deposit-container {
                margin-top: 20px;
            }
        }
        .prize-pool-box {
            h1 {
                font-size: 44px;
                line-height: 52px;
            }
            .countdown-item {
                .countdown {
                    margin: 0 5px;
                }
            }
            .amount-pick-container {
                .predict-amount {
                    padding: 2px;
                    margin: 0 2px;
                    height: 70px;
                    &:first-child {
                        margin-right: 2px;
                    }
                    &:last-child {
                        margin-left: 2px;
                    }
                    h3 {
                        font-size: 16px;
                        line-height: 18px;
                    }
                    h4 {
                        font-size: 10px;
                        line-height: 18px;
                    }
                }
                .selected {
                    .border-predict-amount {
                        padding-top: 15px;
                    }
                }
            }
            .team-pick-container {
                padding: 10px;
                // height: 280px;
                h4 {
                    font-size: 16px;
                }
                .team-picker {
                    margin-top: 10px;
                    img {
                        width: 230px;
                    }
                }
            }
        }
        .prize-warning {
            display: block;
            .prize-warning-item {
                margin: 10px 0;
                width: 100%;
            }
        }
    }
}
@media (max-width: 576px) {
    .prize-pool-container {
        .prize-pool-box {
            .team-pick-container {
                // height: 240px;
                h4 {
                    font-size: 14px;
                }
            }
        }
    }
}
.warning {
    color: #efaa5d;
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;