
                        @import "src/assets/less/variables.less";
                    
.section-shape {
    z-index: 1;
}
.section-shape-beast-1 {
    top: 7%;
    left: 20%;

    img {
        scale: 0.5;
    }
}
.section-shape-beast-2 {
    top: 2%;
    right: 17%;

    img {
        scale: 0.8;
    }
}
.section-shape-beauty-1 {
    top: 2%;
    left: 15%;

    img {
        scale: 0.5;
        transform: rotate(15deg);
    }
}
.section-shape-beauty-2 {
    top: 8%;
    right: 10%;

    img {
        scale: 0.8;
        transform: rotate(-15deg);
    }
}
.section-shape-star-1 {
    top: 1%;
    left: 35%;
}
.section-shape-star-2 {
    top: 4%;
    left: 38%;
}
.section-shape-star-3 {
    top: 6%;
    left: 36%;
}
.section-shape-star-4 {
    top: 6.25%;
    right: 39%;
}
.section-shape-star-5 {
    top: 5%;
    right: 34%;
}
.section-shape-star-6 {
    top: 2%;
    right: 37%;
}
.section-shape-star-7 {
    top: 2.5%;
    right: 41%;
}
.section-shape-mfg-1 {
    top: 5.5%;
    left: 6%;
}
.section-shape-mfg-2 {
    top: 3.6%;
    right: 35%;
}
.section-shape-mfr-1 {
    top: 2.3%;
    left: 35%;
}
.section-shape-mfr-2 {
    top: 4%;
    right: 2%;
}
// .section-shape-beast-1 {
//     top: 12%;
//     left: 20%;

//     img {
//         scale: 0.5;
//     }
// }
// .section-shape-beast-2 {
//     top: 4%;
//     right: 17%;

//     img {
//         scale: 0.8;
//     }
// }
// .section-shape-beauty-1 {
//     top: 5%;
//     left: 15%;

//     img {
//         scale: 0.5;
//         transform: rotate(15deg);
//     }
// }
// .section-shape-beauty-2 {
//     top: 13%;
//     right: 11%;

//     img {
//         scale: 0.8;
//         transform: rotate(-15deg);
//     }
// }
// .section-shape-star-1 {
//     top: 2%;
//     left: 35%;
// }
// .section-shape-star-2 {
//     top: 7%;
//     left: 38%;
// }
// .section-shape-star-3 {
//     top: 12%;
//     left: 36%;
// }
// .section-shape-star-4 {
//     top: 12.25%;
//     right: 39%;
// }
// .section-shape-star-5 {
//     top: 9%;
//     right: 34%;
// }
// .section-shape-star-6 {
//     top: 4%;
//     right: 37%;
// }
// .section-shape-star-7 {
//     top: 5.5%;
//     right: 41%;
// }
// .section-shape-mfg-1 {
//     top: 10.5%;
//     left: 6%;
// }
// .section-shape-mfg-2 {
//     top: 6.6%;
//     right: 35%;
// }
// .section-shape-mfr-1 {
//     top: 4.3%;
//     left: 35%;
// }
// .section-shape-mfr-2 {
//     top: 8%;
//     right: 2%;
// }
@media (max-width: 996px) {
    .section-shape-beast-1,
    .section-shape-beast-2,
    .section-shape-mfr-1,
    .section-shape-mfr-2,
    .section-shape-mfg-1,
    .section-shape-mfg-2,
    .section-shape-beauty-1,
    .section-shape-beauty-2 {
        display: none;
    }
}
@media (max-width: 768px) {
    .section-shape-star-1 {
        top: 2%;
        left: 19%;
    }
    .section-shape-star-2 {
        top: 4%;
        left: 25%;
    }
    .section-shape-star-3 {
        top: 6%;
        left: 28%;
        z-index: -1;
    }
    .section-shape-star-4 {
        top: 5%;
        right: 18%;
    }
    .section-shape-star-5 {
        top: 4%;
        right: 6%;
    }
    .section-shape-star-6 {
        top: 2.5%;
        right: 12%;
    }
    .section-shape-star-7 {
        top: 3%;
        right: 20%;
    }
}

@primary-color: #4CCBC9;@text-color: #A8ADC3;@heading-color: #FFFFFF;@input-bg: #250E3A;@font-family: 'Darker Grotesque', sans-serif;@font-size-base: 18px;@line-height-base: 26px;@body-background: #020722;@btn-font-weight: 800;@btn-height-base: 50px;@btn-padding-horizontal-base: 18px;@btn-border-radius-base: 5px;@btn-primary-color: #020722;@btn-line-height: 49px;@input-padding-vertical-base: 10px;@input-padding-horizontal-base: 15px;@control-border-radius: 5px;@input-border-color: #250E3A;@link-color: #FFFFFF;